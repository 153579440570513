import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Resource from "app/core/resource";
import Env from "app/core/environment";

import StatusTypeEnum from "app/core/utilites/enum/product/status/type";
import SellerEnum from "app/core/utilites/enum/seller";

import BasketService from "app/core/services/basket";

import Price from "components/price/Price";

class Buy extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @property
         * @type {Object}
         * @private
         */
        this._iconNames = {
            basket: "cart",
            inBasket: "cart-check"
        };

        /**
         * @private
         * @property _product
         * @type {Product}
         */
        this._product = props.product;

        /**
         * @private
         * @property stringsResource
         * @type {Object}
         */
        this._stringsResource = Resource.getStrings(Env.getInstance().getLanguage());

        /**
         * @private
         * @property _basketService
         * @type {Basket}
         */
        this._basketService = BasketService.getInstance();

        /**
         * @private
         * @property _statusTypeEnum
         * @type {Enum}
         */
        this._statusTypeEnum = StatusTypeEnum.getInstance();

        /**
         * @private
         * @property _sellerEnum
         * @type {Enum}
         */
        this._sellerEnum = SellerEnum.getInstance();

        /**
         * @property state
         * @type {Object}
         */
        this.state = {
            btnName: this._getDefaultBtnName(),
            inBasket: false,
            isBuyDisabled: false
        };

        this._updateBuyButton = this._updateBuyButton.bind(this);
        this._addToBasket = this._addToBasket.bind(this);
    }

    /**
     * @protected
     * @method componentDidMount
     * @returns {void}
     */
    componentDidMount() {
        // eslint-disable-next-line no-underscore-dangle
        this
            ._updateBuyButton()
            ._basketService
            .on("update", this._updateBuyButton);
    }

    /**
     * @protected
     * @method componentDidMount
     * @returns {void}
     */
    componentWillUnmount() {
        this._basketService.off("update", this._updateBuyButton);
    }

    /**
     * @private
     * @method _isAvailableButton
     * @returns {boolean}
     */
    _isAvailableButton() {
        return this._statusTypeEnum.isAvailable(this._product.getStatus().getType()) &&
            this._sellerEnum.isMain(this._product.getSeller().getAlias());
    }

    /**
     * @private
     * @method _isBuyDisabled
     * @returns {boolean}
     */
    _isBuyDisabled() {
        return this.state.isBuyDisabled;
    }

    /**
     * @method _isPriceVisible
     * @return {boolean}
     * @private
     */
    _isPriceVisible() {
        return this.props.hasPrice && !this.state.inBasket;
    }

    /**
     * @private
     * @method _toggleDisabled
     * @param state {boolean}
     * @returns {BuyBlock}
     */
    _toggleDisabled(state) {
        this.setState({isBuyDisabled: state});

        return this;
    }

    /**
     * @private
     * @method _getClasses
     * @returns {Object}
     */
    _getClasses() {
        return classNames({
            "to-basket d-flex align-items-center btn-sm justify-content-center": true,
            "btn-default": !this.state.inBasket,
            "to-basket--with-price": this.props.hasPrice,
            "btn-default--outline to-basket--in-basket": this.state.inBasket,
            "justify-content-between": this._isPriceVisible()
        }, this.props.className);
    }

    /**
     * @method _getDefaultBtnName
     * @returns {string}
     * @private
     */
    _getDefaultBtnName() {
        return this.props.buttonName || this._stringsResource.addToBasket;
    }

    /**
     * @private
     * @method _getBtnName
     * @returns {string}
     */
    _getBtnName() {
        return (this.props.hasName && this.state.btnName) || "";
    }

    /**
     * @method _getDefaultIconName
     * @return {string}
     * @private
     */
    _getDefaultIconName() {
        return this.state.inBasket ? this._iconNames.inBasket : this._iconNames.basket;
    }

    /**
     * @method _getIconName
     * @return {string}
     * @private
     */
    _getIconName() {
        return `icon-${this.props.iconName || this._getDefaultIconName()}`;
    }

    /**
     * @private
     * @method _getTitle
     * @returns {string}
     */
    _getTitle() {
        return this.state.btnName.toUpperCase();
    }

    /**
     * @private
     * @method _getIconClassNames
     * @returns {object}
     */
    _getIconClassNames() {
        return classNames({
            [this._getIconName()]: this.props.hasIcon,
            "icon--default": true,
            icon: true
        });
    }

    /**
     * @private
     * @method _updateBuyButton
     * @returns {string}
     */
    _updateBuyButton() {
        this._basketService.hasItemById(this._product.getId(), (result) => {
            this.setState(() => ({
                btnName: !result ? this._getDefaultBtnName() : this._stringsResource.inBasket,
                inBasket: result,
                isBuyDisabled: false
            }));
        });

        return this;
    }

    /**
     * @private
     * @method _addToBasket
     * @returns {Buy}
     */
    _addToBasket() {
        this._toggleDisabled(true);

        this.props.addToBasket(this._product, () => {
            this._toggleDisabled(false);
        }, () => {
            this._toggleDisabled(false);
        });

        return this;
    }

    /**
     * @private
     * @method _renderIcon
     * @returns {string}
     */
    _renderIcon() {
        return (
            <span className="buy-icons d-inline-flex align-items-center justify-content-center">
                <span className={this._getIconClassNames()} />
                <span className={classNames("icon icon--hovered", this._getIconName())} />
            </span>
        );
    }

    /**
     * @protected
     * @method render
     * @returns {string}
     */
    render() {
        return this._isAvailableButton() && (
            <button
                className={this._getClasses()}
                type="button"
                title={this._getTitle()}
                disabled={this._isBuyDisabled()}
                onClick={this._addToBasket}
            >
                <span className="d-flex align-items-center">
                    {this.props.hasIcon && this._renderIcon()} {this._getBtnName()}
                </span>

                {this._isPriceVisible() && (
                    <Price value={this._product.getPrice().getCurrent()} className="text-lowercase" />
                )}
            </button>
        );
    }
}

Buy.propTypes = {
    product: PropTypes.instanceOf(Object).isRequired,
    addToBasket: PropTypes.func.isRequired,
    buttonName: PropTypes.string,
    className: PropTypes.string,
    iconName: PropTypes.string,
    hasName: PropTypes.bool,
    hasIcon: PropTypes.bool,
    hasPrice: PropTypes.bool
};

Buy.defaultProps = {
    buttonName: "",
    className: "",
    iconName: "",
    hasName: true,
    hasIcon: false,
    hasPrice: false
};

export default Buy;
