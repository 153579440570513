import Entity from "app/core/entities/Entity";
import Strings from "app/core/utilites/strings";
import Status from "app/core/entities/status/Status";

class StatusHistory extends Entity {
    constructor(entity) {
        super(entity);

        this.strings = Strings.getInstance();
    }

    /**
     * @public
     * @method getStatus
     * @return {Status}
     */
    getStatus() {
        return new Status(this.entity.status);
    }

    /**
     * @public
     * @method getDateAsText
     * @return {string}
     */
    getDateAsText() {
        return this.entity.date ? this.strings.formatDate(new Date(this.entity.date)) : "";
    }
}

export default StatusHistory;
