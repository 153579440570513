/* eslint-disable max-len */

import React from "react";
import PropTypes from "prop-types";

import Env from "app/core/environment";
import Resource from "app/core/resource";

import PhoneChecker from "components/phoneChecker/PhoneChecker";

class Authorization extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @property stringsResource
         * @type {Object}
         */
        this.stringsResource = Resource.getStrings(Env.getInstance().getLanguage());
    }

    /**
     * @method _getTitle
     * @returns {string}
     * @private
     */
    _getTitle() {
        return this.props.title || this.stringsResource.loginToYourAccount;
    }

    /**
     * @private
     * @method render
     * @return {React.element}
     */
    render() {
        return (
            <div className="authorization">
                <div className="authorization__header">
                    <span className="authorization__title">
                        {this._getTitle()}
                    </span>
                </div>

                <div className="authorization__body">
                    <PhoneChecker
                        phone={this.props.login}
                        autoActiveOTP={this.props.autoActiveOTP}
                        confirm={this.props.confirm}
                    />
                </div>
            </div>
        );
    }
}

Authorization.propTypes = {
    title: PropTypes.string,
    login: PropTypes.string,
    autoActiveOTP: PropTypes.bool,
    confirm: PropTypes.func
};

Authorization.defaultProps = {
    title: "",
    login: "380",
    autoActiveOTP: false,
    confirm: () => {}
};

export default Authorization;
