import BaseAnalytics from "app/core/analytics";

class Analytics {
    constructor() {
        /**
         * @property analytics
         * @type {Object}
         */
        this.analytics = BaseAnalytics.getInstance();

        /**
         * @property eventCategory
         * @type {string}
         */
        this.eventCategory = "header";

        /**
         * @property eventActions
         * @type {Object}
         */
        this.eventActions = {
            openBasket: "header_click_basket"
        };
    }

    /**
     * @private
     * @method _sendEvent
     * @param eventAction {string|string[]}
     * @returns {Analytics}
     */
    _sendEvent(eventAction) {
        this.analytics.sendGoogleAnalyticsEvent(this.eventCategory, eventAction);

        return this;
    }

    /**
     * @public
     * @method openBasket
     * @returns {Analytics}
     */
    openBasket() {
        this._sendEvent(this.eventActions.openBasket);

        return this;
    }
}

export default Analytics;
