import Entity from "app/core/entities/Entity";
import Status from "app/core/entities/status/Status";
import StatusHistory from "app/core/entities/status/StatusHistory";
import Profile from "app/core/entities/profile/Profile";
import Basket from "app/core/entities/basket/Basket";
import Delivery from "app/core/entities/delivery/Delivery";
import Payment from "app/core/entities/payment/Payment";
import PriceEntity from "app/core/entities/price/Price";

import Strings from "app/core/utilites/strings";

class Order extends Entity {
    constructor(entity) {
        super(entity);

        this.strings = Strings.getInstance();
    }

    /**
     * @public
     * @method hasCallback
     * @return {boolean}
     */
    hasCallback() {
        return Boolean(this.entity.callback);
    }

    /**
     * @public
     * @method getStatus
     * @returns {Status}
     */
    getStatus() {
        return new Status(this.entity.status);
    }

    /**
     * @public
     * @method getAllStatusHistory
     * @return {StatusHistory[]}
     */
    getStatusHistory() {
        return (this.entity.history || []).map((item) => new StatusHistory(item));
    }

    /**
     * @public
     * @method getCreationDateAsText
     * @return {string}
     */
    getCreationDateAsText() {
        return this.strings.formatDate(new Date(this.entity.createdAt)) || "";
    }

    /**
     * @public
     * @method getCustomer
     * @return {Profile}
     */
    getCustomer() {
        return new Profile(this.entity.customer);
    }

    /**
     * @public
     * @method getRecipient
     * @returns {Profile}
     */
    getRecipient() {
        return new Profile(this.entity.recipient);
    }

    /**
     * @method getDelivery
     * @return {Delivery}
     */
    getDelivery() {
        return new Delivery(this.entity.delivery);
    }

    /**
     * @public
     * @method getPayment
     * @return {Payment}
     */
    getPayment() {
        return new Payment(this.entity.payment);
    }

    /**
     * @public
     * @method getBasket
     * @return {Basket}
     */
    getBasket() {
        return new Basket(this.entity.basket);
    }

    /**
     * @method getDiscount
     * @returns {{getTotalBonusCount(): number}}
     */
    getDiscount() {
        let self = this;

        return {
            /**
             * @method getTotalBonusCount
             * @returns {number}
             */
            getTotalBonusCount() {
                return Number(self.loyalty?.usedBonus) || 0;
            }
        };
    }

    /**
     * @method getPrice
     * @returns {Price}
     */
    getPrice() {
        return new PriceEntity(this.entity.price);
    }
}

export default Order;
