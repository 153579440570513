import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import Env from "app/core/environment";
import Resource from "app/core/resource";

import Timer from "components/timer/Timer";

class DoubleCashback extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @property stringsResource
         * @type {Object}
         */
        this.stringsResource = Resource.getStrings(Env.getInstance().getLanguage());
    }

    /**
     * @private
     * @method _isStartedPromotion
     * @returns {boolean}
     */
    _isStartedPromotion() {
        return Boolean(this.props.bonus.getEntity().expiresAt);
    }

    /**
     * @private
     * @method _getExpireDateForTimer
     * @returns {Date}
     */
    _getExpireDateForTimer() {
        return this._isStartedPromotion() ? this.props.bonus.getExpireDate() : this.props.futureBonus.getStartDate();
    }

    // /**
    //  * @method _getExpireDate
    //  * @returns {Date}
    //  * @private
    //  */
    // _getExpireDate() {
    //     return this.props.expireDate;
    // }
    //
    // /**
    //  * @method _getStartDate
    //  * @returns {Date}
    //  * @private
    //  */
    // _getStartDate() {
    //     return this.props.startDate;
    // }

    /**
     * @method _getTotalTime
     * @returns {number}
     * @private
     */
    _getTotalTime() {
        return this.props.bonus.getExpireDate() - this.props.bonus.getStartDate();
    }

    /**
     * @private
     * @method _getLeftTimeFromStart
     * @returns {number}
     */
    _getLeftTimeFromStart() {
        return new Date() - this.props.bonus.getStartDate();
    }

    /**
     * @private
     * @method _getCurrentPercentOfProgress
     * @returns {number}
     */
    _getCurrentPercentOfProgress() {
        // return Math.min(
        //     Math.max(0, (this._getLeftTimeFromStart() / this._getTotalTime()) * 100),
        //     100
        // );

        return Math.floor(100 - ((this._getLeftTimeFromStart() / this._getTotalTime()) * 100));
    }

    /**
     * @protected
     * @method render
     * @returns {string}
     */
    render() {
        return (
            <div
                className={
                    classNames("promotion promotion--double-cashback", this.props.className, {
                        "promotion--started": this._isStartedPromotion()
                    })
                }
            >
                <div className="promotion__body">
                    {this._isStartedPromotion() && (
                        <div className="promotion__progress-bar progress-bar mb-4px">
                            <div
                                className="progress-bar__progress"
                                style={{width: `${this._getCurrentPercentOfProgress()}%`}}
                            />
                        </div>
                    )}

                    <div className="promotion__about-promotion d-flex">
                        <div className="promotion__double-cashback">
                            {!this._isStartedPromotion() && (
                                <span>{this.stringsResource.will} </span>
                            )}

                            Х2 {this.stringsResource.cashback}
                        </div>

                        <div className="d-flex align-items-center color-black line-height-1">
                            {this._isStartedPromotion() && (
                                <span className="promotion__end d-none d-sm-flex">
                                    {this.stringsResource.restOfTime}&nbsp;
                                </span>
                            )}

                            {!this._isStartedPromotion() && (
                                <span className="promotion__start d-none d-sm-flex">
                                    {this.stringsResource.afterOfTime}&nbsp;
                                </span>
                            )}

                            <Timer
                                hideTitles
                                hideDays={this._isStartedPromotion()}
                                expireDate={this._getExpireDateForTimer()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DoubleCashback.propTypes = {
    className: PropTypes.string,
    bonus: PropTypes.instanceOf(Object).isRequired,
    futureBonus: PropTypes.instanceOf(Object).isRequired
};

DoubleCashback.defaultProps = {
    className: ""
};

export default DoubleCashback;
