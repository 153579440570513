import Env from "app/core/environment";
import Resource from "app/core/resource";

import Strings from "./Strings";

let strings = new Strings({
    dependencies: {
        Resource,
        Env: Env.getInstance()
    }
});

export default {
    getInstance() {
        return strings;
    }
};
