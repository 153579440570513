import _ from "lodash";

import Entity from "app/core/entities/Entity";
import Alert from "app/core/entities/alert/Alert";

class AlertMessage extends Entity {
    /**
     * @method _buildAlerts
     * @param items {Array}
     * @returns {Alert[]}
     * @private
     */
    _buildAlerts(items) {
        return (items || []).map((item) => new Alert(item));
    }

    /**
     * @public
     * @method getWarnings
     * @returns {Alert[]}
     */
    getWarnings() {
        return this._buildAlerts(this.entity.warnings);
    }

    /**
     * @public
     * @method setWarnings
     * @param items {Array}
     * @returns {AlertMessage}
     */
    setWarnings(items) {
        if (_.isArray(items)) {
            this.entity.warnings = items;
        }

        return this;
    }

    /**
     * @public
     * @method getInformation
     * @returns {Alert[]}
     */
    getInformation() {
        return this._buildAlerts(this.entity.information);
    }

    /**
     * @public
     * @method setInformation
     * @param items {Array}
     * @returns {AlertMessage}
     */
    setInformation(items) {
        if (_.isArray(items)) {
            this.entity.information = items;
        }

        return this;
    }

    /**
     * @public
     * @method getErrors
     * @returns {Alert[]}
     */
    getErrors() {
        return this._buildAlerts(this.entity.errors);
    }
}

export default AlertMessage;
