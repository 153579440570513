import Entity from "app/core/entities/Entity";

import MarketingActivity from "app/core/utilites/marketingActivity";

class Sale extends Entity {
    constructor(props) {
        super(props);

        /**
         * @property _marketingActivity
         * @type {MarketingActivity}
         * @private
         */
        this._marketingActivity = MarketingActivity.getInstance();
    }

    /**
     * @public
     * @method hasDoubleBonus
     * @returns {boolean}
     */
    hasDoubleBonus() {
        return this._marketingActivity.getDoubleBonusFactor() === this.getFactor();
    }

    /**
     * @public
     * @method getPercent
     * @returns {number}
     */
    getPercent() {
        return Number(this.entity.percent || 0);
    }

    /**
     * @public
     * @method getFactor
     * @return {number}
     */
    getFactor() {
        return Number(this.entity.multiplier || 1);
    }

    /**
     * @public
     * @method getValue
     * @returns {number}
     */
    getValue() {
        return Number(this.entity.value || 0);
    }

    /**
     * @public
     * @method getStartDate
     * @returns {Date}
     */
    getStartDate() {
        return (this.entity.startsAt && new Date(this.entity.startsAt)) || "";
    }

    /**
     * @public
     * @method getExpireDate
     * @returns {Date}
     */
    getExpireDate() {
        return (this.entity.expiresAt && new Date(this.entity.expiresAt)) || "";
    }
}

export default Sale;
