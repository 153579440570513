import AuthorizationService from "app/core/services/authorization";

class Repository {
    constructor(props) {
        this.urls = props.urls;

        /**
         * @private
         * @property _HttpClient
         * @type {HttpClient}
         */
        this._HttpClient = props.dependencies.HttpClient;

        /**
         * @private
         * @type {Authorization}
         * @private
         */
        this._authorizationService = AuthorizationService.getInstance();

        /**
         * @property _OrderTransformer
         * @type {Transformer}
         * @private
         */
        this._OrderTransformer = props.dependencies.OrderTransformer;

        /**
         * @private
         * @property _httpClient
         * @type {HttpClient}
         */
        this._httpClient = new this._HttpClient();
    }

    /**
     * @public
     * @method getOrders
     * @param success {Function}
     * @param error {Function}
     * @returns {Promise}
     */
    getOrders(success, error) {
        if (this._authorizationService.isAuthorized()) {
            return this._httpClient
                .setBaseUrl(this.urls.getOrders.domain)
                .request({
                    path: this.urls.getOrders.path,
                    method: this._HttpClient.methods.GET
                })
                .then((response) => {
                    success(this._OrderTransformer.transformItems(response.data));
                }, error);
        }
    }

    /**
     * @public
     * @method getStatusHistoryByOrder
     * @param id {string|number}
     * @param success {Function}
     * @param error {Function}
     * @return {Promise}
     */
    getStatusHistoryByOrder(id, success, error) {
        if (this._authorizationService.isAuthorized()) {
            return this._httpClient
                .setBaseUrl(this.urls.getStatusHistoryByOrder.domain)
                .request({
                    path: this.urls.getStatusHistoryByOrder.path,
                    method: this._HttpClient.methods.GET,
                    params: {id}
                })
                .then((response) => {
                    success(this._OrderTransformer.transform(response.data));
                }, error);
        }
    }
}

export default Repository;
