import Env from "app/core/environment";
import Transformer from "./Transformer";

let transformer = new Transformer({
    dependencies: {
        Env: Env.getInstance()
    }
});

export default {
    getInstance() {
        return transformer;
    }
};
