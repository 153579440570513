import Entity from "app/core/entities/Entity";
import Strings from "app/core/utilites/strings";

class Status extends Entity {
    constructor(entity) {
        super(entity);

        this.strings = Strings.getInstance();
    }

    /**
     * @public
     * @method isCompleted
     * @return {boolean}
     */
    isCompleted() {
        return Boolean(this.entity.completed);
    }

    /**
     * @public
     * @method isActive
     * @return {boolean}
     */
    isActive() {
        return Boolean(this.entity.active);
    }

    /**
     * @public
     * @method getType
     * @returns {string}
     */
    getType() {
        return this.entity.type || ""; //"available"
    }

    /**
     * @public
     * @method getName
     * @returns {string}
     */
    getName() {
        return this.entity.name || "";
    }

    /**
     * @public
     * @method getDescription
     * @return {string}
     */
    getDescription() {
        return this.entity.description || "";
    }
}

export default Status;
