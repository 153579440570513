import _ from "lodash";

class Order {
    constructor(props) {
        this._Repository = props.dependencies.Repository;
        this._OrderEntity = props.dependencies.OrderEntity;

        /**
         * @property _orderStatusEnum
         * @type {Enum}
         * @private
         */
        this._orderStatusEnum = props.dependencies.OrderStatusEnum.getInstance();
    }

    /**
     * @private
     * @method _isFinalStatusState
     * @returns {Boolean}
     */
    _isFinalStatusState(order) {
        return this._getFinalStatuses().includes(order.getStatus().getId());
    }

    /**
     * @private
     * @method _getFinalStatuses
     * @returns {Array}
     */
    _getFinalStatuses() {
        return [
            this._orderStatusEnum.getCompletedAsValue(),
            this._orderStatusEnum.getReturnedAsValue(),
            this._orderStatusEnum.getCanceledAsValue()
        ];
    }

    /**
     * @public
     * @method getOrders
     * @param success {Function}
     * @param error {Function}
     * @returns {Order}
     */
    getOrders(success, error) {
        if (_.isFunction(success) && _.isFunction(error)) {
            this._Repository.getOrders(success, error);
        }

        return this;
    }

    /**
     * @public
     * @method getStatusHistoryByOrder
     * @param id {string|number}
     * @param success {Function}
     * @param error {Function}
     * @return {Order}
     */
    getStatusHistoryByOrder(id, success, error) {
        if (id && _.isFunction(success) && _.isFunction(error)) {
            this._Repository.getStatusHistoryByOrder(id, success, error);
        }

        return this;
    }

    /**
     * @public method getIncompleteOrders
     * @param orders {Array}
     * @returns {Order[]}
     */
    getIncompleteOrders(orders) {
        return orders.filter((order) => !this._isFinalStatusState(this.convertOrderToEntity(order)));
    }

    /**
     * @public
     * @method convertOrderToEntity
     * @param order {Object}
     * @returns {Order}
     */
    convertOrderToEntity(order) {
        return new this._OrderEntity(order);
    }
}

export default Order;
