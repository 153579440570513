/* eslint-disable max-len */

import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Env from "app/core/environment";

import Validator from "app/core/utilites/validator/Validator";
import Algorithms from "app/core/utilites/validator/Algorithms";

import Resource from "app/core/resource";

import AuthorizationService from "app/core/services/authorization";

import PhoneStep from "./step/Phone";
import OTPStep from "./step/OTP";

class PhoneChecker extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @property stringsResource
         * @type {Object}
         */
        this.stringsResource = Resource.getStrings(Env.getInstance().getLanguage());

        this.state = {
            isLoading: false,
            previousPhone: "",
            phone: props.phone || "",
            otpCode: "",
            errorMessages: {
                phone: "",
                otp: ""
            },
            steps: {
                disabledPhone: false,
                activeOTP: false
            }
        };

        this.authorizationService = AuthorizationService.getInstance();

        this.Resource = Resource;
        this.Validator = Validator;
        this.Algorithms = Algorithms;

        this._toggleLoader = this._toggleLoader.bind(this);

        this._changePhone = this._changePhone.bind(this);
        this._confirmPhone = this._confirmPhone.bind(this);
        this._confirmOTPCode = this._confirmOTPCode.bind(this);

        this._toOTPStep = this._toOTPStep.bind(this);
        this._verifyOTP = this._verifyOTP.bind(this);
    }

    /**
     * @method _isLoading
     * @return {boolean}
     * @private
     */
    _isLoading() {
        return this.state.isLoading;
    }

    /**
     * @method _isActiveOTPStep
     * @return {boolean}
     * @private
     */
    _isActiveOTPStep() {
        return this.state.steps.activeOTP;
    }

    /**
     * @method _isChangedPhone
     * @param phone {string}
     * @return {boolean}
     * @private
     */
    _isChangedPhone(phone) {
        return phone !== this._getPreviousPhone();
    }

    /**
     * @method _isDisabledPhoneStep
     * @return {boolean}
     * @private
     */
    _isDisabledPhoneStep() {
        return this.state.steps.disabledPhone;
    }

    /**
     * @method _toggleLoader
     * @param state {boolean}
     * @return {PhoneChecker}
     * @private
     */
    _toggleLoader(state = false) {
        this.setState((currentState) => _.merge({}, currentState, {isLoading: state}));

        return this;
    }

    /**
     * @method _toggleDisabledPhoneStep
     * @param state {boolean}
     * @return {PhoneChecker}
     * @private
     */
    _toggleDisabledPhoneStep(state = false) {
        this.setState((currentState) => _.merge({}, currentState, {steps: {disabledPhone: state}}));

        return this;
    }

    /**
     * @method _getPhone
     * @return {string}
     * @private
     */
    _getPhone() {
        return this.state.phone;
    }

    /**
     * @method
     * @param phone {string}
     * @param [callback] {Function}
     * @return {PhoneChecker}
     * @private
     */
    _setPhone(phone, callback) {
        this.setState({phone}, callback);

        return this;
    }

    /**
     * @method _getPreviousPhone
     * @return {string}
     * @private
     */
    _getPreviousPhone() {
        return this.state.previousPhone;
    }

    /**
     * @method _getOTPCode
     * @return {string}
     * @private
     */
    _getOTPCode() {
        return this.state.otpCode;
    }

    /**
     * @method _setOTPCode
     * @param otpCode {string}
     * @param [callback] {Function}
     * @return {PhoneChecker}
     * @private
     */
    _setOTPCode(otpCode, callback) {
        this.setState({otpCode}, callback);

        return this;
    }

    /**
     * @method _getOTPMaxLength
     * @return {number}
     * @private
     */
    _getOTPMaxLength() {
        return this.OTPMaxLength;
    }

    /**
     * @method _getPhoneErrorMessage
     * @return {string}
     * @private
     */
    _getPhoneErrorMessage() {
        return this.state.errorMessages.phone;
    }

    /**
     * @method _getOTPErrorMessage
     * @return {string}
     * @private
     */
    _getOTPErrorMessage() {
        return this.state.errorMessages.otp;
    }

    /**
     * @method _setPhoneErrorMessage
     * @param [message] {string}
     * @return {PhoneChecker}
     * @private
     */
    _setPhoneErrorMessage(message = "") {
        this.setState((state) => _.merge({}, state, {errorMessages: {phone: message}}));

        return this;
    }

    /**
     * @method _setOTPErrorMessage
     * @param [message] {string}
     * @return {PhoneChecker}
     * @private
     */
    _setOTPErrorMessage(message = "") {
        this.setState((state) => _.merge({}, state, {errorMessages: {otp: message}}));

        return this;
    }

    /**
     * @method _saveCurrentPhone
     * @return {PhoneChecker}
     * @private
     */
    _saveCurrentPhone() {
        this.setState((currentState) => _.merge({}, currentState, {previousPhone: this._getPhone()}));

        return this;
    }

    /**
     * @method _changePhone
     * @param phone {string}
     * @return {PhoneChecker}
     * @private
     */
    _changePhone(phone) {
        if (this._isActiveOTPStep()) {
            // eslint-disable-next-line no-underscore-dangle
            this
                ._toggleDisabledPhoneStep(!this._isChangedPhone(phone))
                ._changeStep(!this._isChangedPhone(phone));
        }

        return this;
    }

    /**
     * @method _confirmPhone
     * @returns {PhoneChecker}
     */
    _confirmPhone(phone) {
        this._setPhone(phone, this._toOTPStep);

        return this;
    }

    /**
     * @method _confirmOTPCode
     * @return {PhoneChecker}
     * @private
     */
    _confirmOTPCode(otpCode) {
        this._setOTPCode(otpCode, this._verifyOTP);

        return this;
    }

    /**
     * @method _changeStep
     * @param [activeOTP] {boolean}
     * @return {PhoneChecker}
     * @private
     */
    _changeStep(activeOTP = false) {
        this.setState(function (state) {
            return _.merge({}, state, {
                steps: {activeOTP}
            });
        });

        return this;
    }

    /**
     * @method _generateOTP
     * @param [success] {Function}
     * @param [error] {Function}
     * @return {PhoneChecker}
     * @private
     */
    _generateOTP(success = () => {}, error = () => {}) {
        this
            ._toggleLoader(true)
            .authorizationService
            .generateOTP(this._getPhone(), () => {
                // eslint-disable-next-line no-underscore-dangle
                this
                    ._toggleDisabledPhoneStep(true)
                    ._saveCurrentPhone()
                    ._setPhoneErrorMessage()
                    ._setOTPErrorMessage()
                    ._toggleLoader();

                success();
            }, (exception) => {
                // eslint-disable-next-line no-underscore-dangle
                this
                    ._toggleLoader()
                    ._setOTPErrorMessage()
                    ._setPhoneErrorMessage(exception.getMessage());

                error();
            });

        return this;
    }

    /**
     * @method _verifyOTP
     * @return {PhoneChecker}
     * @private
     */
    _verifyOTP() {
        this._toggleLoader(true);

        this
            .authorizationService
            .verifyOTP(
                this._getPhone(),
                this._getOTPCode(),
                () => {
                    // eslint-disable-next-line no-underscore-dangle
                    this._setOTPErrorMessage()._toggleLoader();

                    this.props.confirm();
                },
                (error) => {
                    // eslint-disable-next-line no-underscore-dangle
                    this._setOTPErrorMessage(error.getMessage())._toggleLoader();
                }
            );

        return this;
    }

    /**
     * @method _toOTPStep
     * @return {PhoneChecker}
     * @private
     */
    _toOTPStep() {
        this
            ._generateOTP(
                () => {
                    // eslint-disable-next-line no-underscore-dangle
                    this._changeStep(true);
                },
                () => {
                    this._changeStep(false);
                }
            );

        return this;
    }

    /**
     * @public
     * @method render
     * @return {React.element}
     */
    render() {
        return (
            <div
                className={classNames("phone-checker", {
                    loading: this._isLoading()
                })}
            >
                <div className="phone-checker__body">
                    <PhoneStep
                        disabled={this._isDisabledPhoneStep()}
                        autoActive={this.props.autoActiveOTP}
                        phone={this._getPhone()}
                        change={this._changePhone}
                        confirm={this._confirmPhone}
                        errorMessage={this._getPhoneErrorMessage()}
                    />

                    {this._isActiveOTPStep() && (
                        <OTPStep
                            confirm={this._confirmOTPCode}
                            retryGenerateOTP={this._toOTPStep}
                            errorMessage={this._getOTPErrorMessage()}
                        />
                    )}
                </div>
            </div>
        );
    }
}

PhoneChecker.propTypes = {
    phone: PropTypes.string,
    autoActiveOTP: PropTypes.bool,
    confirm: PropTypes.func
};

PhoneChecker.defaultProps = {
    phone: "380",
    autoActiveOTP: false,
    confirm: () => {}
};

export default PhoneChecker;
