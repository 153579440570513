import BaseAnalytics from "app/core/analytics";

class Analytics {
    constructor() {
        /**
         * @property analytics
         * @type {Object}
         */
        this.analytics = BaseAnalytics.getInstance();
        this.event = {
            viewItemList: "view_item_list",
            selectItem: "select_item",
            viewItem: "view_item"
        };
    }

    /**
     * @private
     * @method _isPushedEvent
     * @returns {boolean}
     * @param eventEcommerce
     */
    _isPushedEvent(eventEcommerce) {
        try {
            return window && window.dataLayer && window.dataLayer.some((event) => event.event === eventEcommerce);
        } catch (e) {
            return false;
        }
    }

    /**
     * @private
     * @method _sendEvent
     * @param event {Object}
     * @returns {Analytics}
     */
    _sendEvent(event) {
        if (!this._isPushedEvent(event.event)) {
            console.log(event);
            (window.dataLayer || []).push(event);
        }

        return this;
    }

    /**
     * @method viewItemListProducts
     * @param products {Catalog}
     * @param title {string}
     * @returns {Analytics}
     */
    viewItemListProducts(products, title) {
        try {
            this._sendEvent({
                event: this.event.viewItemList,
                ecommerce: {
                    items: products.map((item) => ({
                        item_name: item.entity.name,
                        item_id: item.entity.id,
                        price: item.entity.price.current,
                        item_category: title,
                        quantity: 1
                    }))
                }
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }

        return this;
    }

    /**
     * @param product
     * @param title
     * @returns {Analytics}
     */
    selectItemProducts(product, title) {
        try {
            this._sendEvent({
                event: this.event.selectItem,
                ecommerce: {
                    items: [
                        {
                            item_name: product.entity.name,
                            item_id: product.entity.id,
                            price: product.entity.price.current,
                            item_category: title,
                            quantity: 1
                        }
                    ]
                }
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }

        return this;
    }

    /**
     * @param product
     */
    viewItemProducts(product) {
        try {
            const categories = product.entity.breadcrumbs.map((item, index) => ({
                [`item_category${index === 0 ? "" : index}`]: item.name
            }));
            const categoriesObject = categories.reduce((acc, current) => ({...acc, ...current}), {});

            this._sendEvent({
                event: this.event.viewItem,
                ecommerce: {
                    items: [
                        {
                            item_name: product.entity.name,
                            item_id: product.entity.id,
                            price: product.entity.price.current,
                            item_brand: product.entity.brand?.name,
                            quantity: 1,
                            ...categoriesObject
                        }
                    ]
                }
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }
}

export default Analytics;
