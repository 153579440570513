import Entity from "app/core/entities/Entity";
import Image from "app/core/entities/image/Image";
import AlertMessage from "app/core/entities/alert/AlertMessage";

class Payment extends Entity {
    /**
     * @public
     * @method isAvailable
     * @return {boolean}
     */
    isAvailable() {
        return Boolean(this.entity.available);
    }

    /**
     * @public
     * @method getLogo
     * @returns {Image}
     */
    getLogo() {
        return new Image(this.entity.logo);
    }

    /**
     * @public
     * @method getTitle
     * @returns {string}
     */
    getTitle() {
        return this.entity.name || "";
    }

    /**
     * @public
     * @method getDescription
     * @returns {string}
     */
    getDescription() {
        return this.entity.description || "";
    }

    /**
     * @public
     * @method getAlertMessage
     * @returns {AlertMessage}
     */
    getAlertMessage() {
        return new AlertMessage(this.entity.alertMessage);
    }
}

export default Payment;
