/* eslint-disable max-len */

import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

import Env from "app/core/environment";

import Resource from "app/core/resource";

import Timer from "components/timer/Timer";

class OTP extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @private
         * @property _OTPMaxLength
         * @type {number}
         */
        this._OTPMaxLength = 4;

        /**
         * @private
         * @property _timeoutAsMilliseconds
         * @type {number}
         */
        this._timeoutAsMilliseconds = 90000;

        this.state = {
            isAvailableOTP: true,
            nextTryTime: null,
            otpCode: ""
        };

        /**
         * @private
         * @property _stringsResource
         * @type {Object}
         */
        this._stringsResource = Resource.getStrings(Env.getInstance().getLanguage());

        this._changeOTPCode = this._changeOTPCode.bind(this);
        this._retryGenerateOTP = this._retryGenerateOTP.bind(this);
        this._confirmCode = this._confirmCode.bind(this);
    }

    /**
     * @public
     * @method componentDidMount
     * @return {void}
     */
    componentDidMount() {
        // eslint-disable-next-line no-underscore-dangle
        this._toggleAvailableOTP()._generateNextTryTime();
    }

    /**
     * @method _isAvailableOTP
     * @return {boolean}
     * @private
     */
    _isAvailableOTP() {
        return this.state.isAvailableOTP;
    }

    /**
     * @method _isValidOTP
     * @return {boolean}
     * @private
     */
    _isValidOTP() {
        return this._getOTPCode().length === this._getOTPMaxLength();
    }

    /**
     * @method _toggleAvailableOTP
     * @param state {boolean}
     * @return {OTP}
     * @private
     */
    _toggleAvailableOTP(state = false) {
        this.setState((currentState) => _.merge({}, currentState, {isAvailableOTP: state}));

        return this;
    }

    /**
     * @method _getTimeoutValue
     * @return {number}
     * @private
     */
    _getTimeoutValue() {
        return this._timeoutAsMilliseconds;
    }

    /**
     * @method _getOTPCode
     * @return {string}
     * @private
     */
    _getOTPCode() {
        return this.state.otpCode;
    }

    /**
     * @method _getOTPMaxLength
     * @return {number}
     * @private
     */
    _getOTPMaxLength() {
        return this._OTPMaxLength;
    }

    /**
     * @method _getNextTryTime
     * @return {Date}
     * @private
     */
    _getNextTryTime() {
        return this.state.nextTryTime;
    }

    /**
     * @method _getErrorMessage
     * @return {OTP}
     * @private
     */
    _getErrorMessage() {
        return this.props.errorMessage;
    }

    /**
     * @method _changeOTPCode
     * @param e {Object}
     * @return {OTP}
     * @private
     */
    _changeOTPCode(e) {
        e.persist();

        this.setState((state) => _.merge({}, state, {otpCode: e.target.value}), this._confirmCode);

        return this;
    }

    /**
     * @method _generateNextTryTime
     * @return {OTP}
     * @private
     */
    _generateNextTryTime() {
        this.setState((state) => _.merge({}, state, {
            nextTryTime: new Date(new Date().getTime() + this._getTimeoutValue())
        }));

        return this;
    }

    /**
     * @method _retryGenerateOTP
     * @return {OTP}
     * @private
     */
    _retryGenerateOTP() {
        if (this._isAvailableOTP()) {
            // eslint-disable-next-line no-underscore-dangle
            this._toggleAvailableOTP()._generateNextTryTime();

            this.props.retryGenerateOTP();
        }

        return this;
    }

    /**
     * @method _confirmCode
     * @return {OTP}
     * @private
     */
    _confirmCode() {
        if (this._isValidOTP()) {
            this.props.confirm(this._getOTPCode());
        }

        return this;
    }

    /**
     * @method _renderTimer
     * @return {React.element}
     */
    _renderTimer() {
        return (
            <Timer expireDate={this._getNextTryTime()} expire={() => this._toggleAvailableOTP(true)} />
        );
    }

    /**
     * @public
     * @method render
     * @return {React.element}
     */
    render() {
        return (
            <div className="phone-checker__step step step--otp">
                <div className="step__header">
                    {this._stringsResource.sentSMSWithCode}
                </div>

                <div className="step__body">
                    <div className="step__field">
                        <div className="outlined-text-form">
                            <InputMask
                                autoFocus
                                mask="9999"
                                maskChar={null}
                                type="text"
                                className="form-control"
                                required
                                value={this._getOTPCode()}
                                onChange={this._changeOTPCode}
                            />

                            <label>{this._stringsResource.enterCodeFromSMSOrViber}</label>
                        </div>

                        <div className="error-message">
                            {this._getErrorMessage()}
                        </div>
                    </div>

                    <div className="step__repeat-otp">
                        <span
                            className="cursor-pointer text-decoration-underline"
                            onClick={this._retryGenerateOTP}
                        >
                            {this._stringsResource.sendCodeAgain}
                        </span>

                        {!this._isAvailableOTP() && (
                            <div className="d-flex">
                                <span>{this._stringsResource.availableThrough.toLowerCase()}&nbsp;</span> {this._renderTimer()}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

OTP.propTypes = {
    errorMessage: PropTypes.string,
    confirm: PropTypes.func,
    retryGenerateOTP: PropTypes.func
};

OTP.defaultProps = {
    errorMessage: "",
    confirm: () => {},
    retryGenerateOTP: () => {}
};

export default OTP;
