import Env from "app/core/environment";

import HttpClient from "app/core/utilites/httpClient/HttpClient";
import OrderTransformer from "app/core/utilites/entityTransformer/order/fromMyOrders";

import OrderStatusEnum from "app/core/utilites/enum/order/status";

import OrderEntity from "app/core/entities/order/Order";

import Repository from "./repository/Repository";
import Order from "./Order";

let order = new Order({
    dependencies: {
        Repository: new Repository({
            dependencies: {
                HttpClient,
                OrderTransformer: OrderTransformer.getInstance()
            },
            urls: {
                getOrders: {
                    domain: Env.getInstance().getAuthorizationServiceHost(),
                    path: "/api/user-orders",
                    params: {},
                    query: {}
                },
                getStatusHistoryByOrder: {
                    domain: Env.getInstance().getAuthorizationServiceHost(),
                    path: "/api/user-orders/:id",
                    params: {
                        id: ""
                    },
                    query: {}
                }
            }
        }),
        OrderStatusEnum,
        OrderEntity
    }
});

export default {
    getInstance() {
        return order;
    }
};
