import Enum from "app/core/utilites/enum/Enum";

let productStatusEnum = new Enum({
    fields: [
        {key: "newOrder", value: "D"},
        {key: "checking", value: "B"},
        {key: "inProgress", value: "N"},
        {key: "inTransitTo", value: "K"},
        {key: "arrived", value: "M"},
        {key: "completed", value: "O"},
        {key: "returned", value: "R"},
        {key: "canceled", value: "A"}
    ]
});

export default {
    getInstance() {
        return productStatusEnum;
    }
};
