/* eslint-disable max-len */

import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

import Env from "app/core/environment";

import Validator from "app/core/utilites/validator/Validator";
import Algorithms from "app/core/utilites/validator/Algorithms";

import Resource from "app/core/resource";

class Phone extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @property
         * @type {Object}
         */
        this.rootElementRef = React.createRef();

        /**
         * @property patterns
         * @type {{specialCharsOfPhone: RegExp}}
         */
        this.patterns = {
            specialCharsOfPhone: /[\+\s()-]/g
        };

        /**
         * @property validationErrors
         * @type {Object}
         */
        this.validationErrors = {
            phone: {
                selector: ".error-phone-field"
            }
        };

        this.state = {
            phone: props.phone || ""
        };

        /**
         * @property stringsResource
         * @type {Object}
         */
        this.stringsResource = Resource.getStrings(Env.getInstance().getLanguage());

        this.Resource = Resource;
        this.Validator = Validator;
        this.Algorithms = Algorithms;

        this._changePhone = this._changePhone.bind(this);
        this._change = this._change.bind(this);
        this._confirm = this._confirm.bind(this);
    }

    /**
     * @public
     * @method componentDidMount
     * @returns {void}
     */
    componentDidMount() {
        if (this.props.autoActive && this.props.phone) {
            // eslint-disable-next-line no-underscore-dangle
            this._change()._confirm();
        }
    }

    /**
     * @method _isValidPhone
     * @param isTriggerError {boolean}
     * @return {boolean}
     * @private
     */
    _isValidPhone(isTriggerError = true) {
        let report = new this.Validator({phone: `+${this._getPhone()}`}, this._getAlgorithms()).validate();

        if (isTriggerError) {
            this.Validator.toggleValidateErrors(report, this._getRootElement());
        }

        return !report.hasError();
    }

    /**
     * @method _getAlgorithms
     * @return {Object}
     * @private
     */
    _getAlgorithms() {
        return new this.Algorithms().getAlgorithms([
            {
                type: "phone",
                selector: this.validationErrors.phone.selector
            }
        ]);
    }

    /**
     * @private
     * @method _getRootElement
     * @returns {Object}
     */
    _getRootElement() {
        return this.rootElementRef && this.rootElementRef.current;
    }

    /**
     * @method _getPhone
     * @return {string}
     * @private
     */
    _getPhone() {
        return this.state.phone.replace(this.patterns.specialCharsOfPhone, "");
    }

    /**
     * @method _getErrorMessage
     * @return {Phone}
     * @private
     */
    _getErrorMessage() {
        return this.props.errorMessage;
    }

    /**
     * @method _changePhone
     * @param e {Object}
     * @returns {Phone}
     */
    _changePhone(e) {
        e.persist();

        this.setState((state) => _.merge({}, state, {phone: e.target.value}), this._change);

        return this;
    }

    /**
     * @method _change
     * @return {Phone}
     * @private
     */
    _change() {
        if (this._isValidPhone(false)) {
            this.props.change(this._getPhone());
        }

        return this;
    }

    /**
     * @method _confirm
     * @return {Phone}
     * @private
     */
    _confirm() {
        if (this._isValidPhone()) {
            this.props.confirm(this._getPhone());
        }

        return this;
    }

    /**
     * @public
     * @method render
     * @return {React.element}
     */
    render() {
        return (
            <div ref={this.rootElementRef} className="phone-checker__step step step--phone">
                <div className="step__body">
                    <div className="step__field">
                        <div className="outlined-text-form">
                            <InputMask
                                mask="+38 (999) 999-99-99"
                                maskChar={null}
                                type="text"
                                className="form-control"
                                required
                                value={this._getPhone()}
                                onChange={this._changePhone}
                            />

                            <label>{this.stringsResource.phone}</label>
                        </div>

                        <div className="error-message error-phone-field" />

                        <div className="error-message">
                            {this._getErrorMessage()}
                        </div>
                    </div>
                </div>

                <div className="step__footer">
                    {!this.props.disabled && (
                        <button
                            type="button"
                            className="btn-default btn-md btn-block text-uppercase"
                            onClick={this._confirm}
                        >
                            {this.stringsResource.next}
                        </button>
                    )}

                    {/*<p>*/}
                    {/*    {this.stringsResource.userAgreement.acceptTerms}*/}
                    {/*    <br />*/}
                    {/*    <a href={this.Resource.links.userAgreement} target="_blank">*/}
                    {/*        {this.stringsResource.userAgreement.agreement.toLowerCase()}*/}
                    {/*    </a>*/}
                    {/*</p>*/}
                </div>
            </div>
        );
    }
}

Phone.propTypes = {
    phone: PropTypes.string,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
    autoActive: PropTypes.bool,
    change: PropTypes.func,
    confirm: PropTypes.func
};

Phone.defaultProps = {
    phone: "380",
    errorMessage: "",
    disabled: false,
    autoActive: false,
    change: () => {},
    confirm: () => {}
};

export default Phone;
