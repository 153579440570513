import _ from "lodash";

import Entity from "app/core/entities/Entity";
import Image from "app/core/entities/image/Image";
import AlertMessage from "app/core/entities/alert/AlertMessage";

class Delivery extends Entity {
    /**
     * @public
     * @method isAvailableABTest
     * @return {boolean}
     */
    isAvailableABTest() {
        let idOfDeliveryTypeForABTest = 1;

        return this.getId() === idOfDeliveryTypeForABTest;
    }

    /**
     * @public
     * @method hasEstimatedDate
     * @return {boolean}
     */
    hasEstimatedDate() {
        return Boolean(this.entity.date) && this.entity.isAvailableDate;
    }

    /**
     * @method getABTestName
     * @returns {string}
     */
    getABTestName() {
        let defaultABTestName = "b";

        return this.isAvailableABTest() ? String(this.entity.ABTestName || defaultABTestName).toLowerCase() : "";
    }

    /**
     * @public
     * @method getLogo
     * @returns {Image}
     */
    getLogo() {
        return new Image(this.entity.logo);
    }

    /**
     * @public
     * @method getTitle
     * @returns {string}
     */
    getTitle() {
        return this.entity.title || "";
    }

    /**
     * @public
     * @method getType
     * @return {number|string}
     */
    getType() {
        return this.entity.type || 0;
    }

    /**
     * @public
     * @method getServiceType
     * @return {number|string}
     */
    getServiceType() {
        return this.entity.serviceType || 0;
    }

    /**
     * @public
     * @method getTTN
     * @return {string}
     */
    getTTN() {
        return this.entity.ttn || "";
    }

    /**
     * @public
     * @method getDescription
     * @returns {string}
     */
    getDescription() {
        return this.entity.description || "";
    }

    /**
     * @public
     * @method getAlertMessage
     * @returns {AlertMessage}
     */
    getAlertMessage() {
        return new AlertMessage(this.entity.alertMessage);
    }

    /**
     * @public
     * @method getAddressAsText
     * @return {string}
     */
    getAddressAsText() {
        return this.entity.address || "";
    }

    /**
     * @public
     * @method getEstimatedDateAsDate
     * @return {Date}
     */
    getEstimatedDateAsDate() {
        return new Date(this.entity.date);
    }

    /**
     * @public
     * @method getEstimatedDateAsISO
     * @return {string}
     */
    getEstimatedDateAsISO() {
        return this.entity.date || "";
    }

    /**
     * @public
     * @method setEstimatedDateAsISO
     * @param date {string}
     * @return {Delivery}
     */
    setEstimatedDateAsISO(date) {
        if (date && _.isString(date)) {
            this.entity.date = date;
        }

        return this;
    }

    /**
     * @public
     * @method getPrice
     * @returns {number}
     */
    getPrice() {
        return Number(this.entity.price) || 0;
    }
}

export default Delivery;
