import Env from "app/core/environment";

let env = Env.getInstance();

export default [
    {
        id: 2,
        name: "Медикаменти",
        url: "https:\/\/www.apteka24.ua\/medikamenty\/",
        icon: `${env.getMainImageRepository()}/icons/menu/Meds&Drugs.svg`,
        promo: {
            url: "",
            preview: "@Image"
        },
        popular: {
            title: "Популярні категорії",
            items: []
        },
        subItems: [
            {
                id: 10,
                name: "Препарати від застуди",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/respiratornaja-sistema\/",
                childrens: [
                    {
                        id: 522,
                        name: "Жарознижуючі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/respiratornaja-sistema\/zharoponizhayushchie\/",
                        childrens: []
                    },
                    {
                        id: 523,
                        name: "Жарознижувальні для дітей",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/respiratornaja-sistema\/zharoponizhayushchie-dlya-detey-\/",
                        childrens: []
                    },
                    {
                        id: 71,
                        name: "Препарати від обструктивних захворювань легень",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/respiratornaja-sistema\/obstruktivnie-zabolevaniya-legkih\/",
                        childrens: []
                    },
                    {
                        id: 72,
                        name: "Препарати від кашлю та застуди",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/respiratornaja-sistema\/kashel-i-prostuda\/",
                        childrens: []
                    },
                    {
                        id: 73,
                        name: "Препарати назальної терапії",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/respiratornaja-sistema\/nazaljnaja-terapija\/",
                        childrens: []
                    },
                    {
                        id: 74,
                        name: "Препарати для респіраторної системи",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/respiratornaja-sistema\/dejstvie-na-respiratornuyu-sistemu\/",
                        childrens: []
                    },
                    {
                        id: 75,
                        name: "Захворювання горла препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/respiratornaja-sistema\/zabolevaniya-gorla\/",
                        childrens: []
                    },
                    {
                        id: 593,
                        name: "Препарати при сухому кашлі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/respiratornaja-sistema\/preparaty-pri-sukhom-kashle\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 33,
                name: "Контрастні речовини",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/rentgenokontrastnye-sredstva\/",
                childrens: []
            },
            {
                id: 42,
                name: "Гінекологічні препарати",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/ginekologija\/",
                childrens: [
                    {
                        id: 534,
                        name: "Вагінальні свічки",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/ginekologija\/vaginalnye-svechi-\/",
                        childrens: []
                    },
                    {
                        id: 316,
                        name: "Препарати від молочниці",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/ginekologija\/lechenie-molochnitsy\/",
                        childrens: []
                    },
                    {
                        id: 317,
                        name: "Препарати від кольпіту",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/ginekologija\/lechenie-kolpita\/",
                        childrens: []
                    },
                    {
                        id: 318,
                        name: "Засоби для лікування міоми",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/ginekologija\/lechenie-miomy\/",
                        childrens: []
                    },
                    {
                        id: 319,
                        name: "Препарати від ендометріозу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/ginekologija\/lechenie-endometrioza\/",
                        childrens: []
                    },
                    {
                        id: 320,
                        name: "Препарати від кісти яєчника",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/ginekologija\/lechenie-kisty-yaichnika\/",
                        childrens: []
                    },
                    {
                        id: 321,
                        name: "Внутрішньоматкові спіралі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/ginekologija\/vnutrimatochnye-spirali\/",
                        childrens: []
                    },
                    {
                        id: 322,
                        name: "Препарати при гіпертонусі матки",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/ginekologija\/pri-gipertonuse-matki\/",
                        childrens: []
                    },
                    {
                        id: 323,
                        name: "Препарати при дисфункції яєчників",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/ginekologija\/pri-disfunktsii-yaichnikov\/",
                        childrens: []
                    },
                    {
                        id: 324,
                        name: "Препарати при клімаксі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/ginekologija\/pri-klimakse\/",
                        childrens: []
                    },
                    {
                        id: 375,
                        name: "Контрацепція",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/ginekologija\/kontratseptsiya\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 7,
                name: "Дерматологічні препарати",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/",
                childrens: [
                    {
                        id: 60,
                        name: "Засоби для загоєння ран",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/lechenie-ran-i-yazv\/",
                        childrens: []
                    },
                    {
                        id: 61,
                        name: "Протисвербіжні засоби",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/protivozudnie-sredstva\/",
                        childrens: []
                    },
                    {
                        id: 62,
                        name: "Протигрибкові засоби у дерматології",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/protivogribkovie-v-dermatologii\/",
                        childrens: []
                    },
                    {
                        id: 64,
                        name: "Препарати від акне та вугрового висипу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/akne-ugrevaya-sip\/",
                        childrens: []
                    },
                    {
                        id: 65,
                        name: "Кортикостероїди препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/kortikosteroidy-v-dermatologii\/",
                        childrens: []
                    },
                    {
                        id: 66,
                        name: "Антисептики та дезінфікуючі засоби",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/antiseptiki-i-dezinfitciruyushie-sredstva\/",
                        childrens: []
                    },
                    {
                        id: 361,
                        name: "Препарати з пом'якшувальною та анестезуючою дією",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/preparaty-so-smyagchayushchim-i-anesteziruyushchim-deystviem\/",
                        childrens: []
                    },
                    {
                        id: 362,
                        name: "Препарати для лікування ран, виразок та пролежнів",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/preparaty-dlya-lecheniya-ran-yazv-i-prolezhney\/",
                        childrens: []
                    },
                    {
                        id: 364,
                        name: "Препарати для лікування псоріазу, екземи",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/preparaty-dlya-lechenie-psoriaza-ekzemy\/",
                        childrens: []
                    },
                    {
                        id: 365,
                        name: "Протимікробні препарати для лікування захворювань шкіри",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/protivomikrobnye-preparaty-dlya-lecheniya-zabolevaniy-kozhi\/",
                        childrens: []
                    },
                    {
                        id: 366,
                        name: "Препарати для лікування рубців",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/preparaty-dlya-lecheniya-rubtsov\/",
                        childrens: []
                    },
                    {
                        id: 367,
                        name: "Препарати від бородавок",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/lechenie-borodavok\/",
                        childrens: []
                    },
                    {
                        id: 368,
                        name: "Засоби від вошей та корости",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/sredstva-ot-vshey\/",
                        childrens: []
                    },
                    {
                        id: 27,
                        name: "Протигрибкові засоби",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/dermatologicheskie-sredstva\/protivogribkovye-sredstva\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 16,
                name: "Препарати від алергії",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/allergija\/",
                childrens: [
                    {
                        id: 256,
                        name: "Алергічний кашель препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/allergija\/allergicheskiy-kashel\/",
                        childrens: []
                    },
                    {
                        id: 257,
                        name: "Алергічний риніт препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/allergija\/allergicheskiy-rinit\/",
                        childrens: []
                    },
                    {
                        id: 258,
                        name: "Алергічний висип препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/allergija\/allergicheskaya-syp\/",
                        childrens: []
                    },
                    {
                        id: 259,
                        name: "Алергічний дерматит препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/allergija\/allergicheskiy-dermatit\/",
                        childrens: []
                    },
                    {
                        id: 260,
                        name: "Алергічний висип у дітей препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/allergija\/allergicheskaya-syp-u-detey\/",
                        childrens: []
                    },
                    {
                        id: 261,
                        name: "Алергічна реакція препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/allergija\/allergicheskaya-reaktsiya\/",
                        childrens: []
                    },
                    {
                        id: 262,
                        name: "Препарати від анафілактичного шоку",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/allergija\/anafilakticheskiy-shok\/",
                        childrens: []
                    },
                    {
                        id: 263,
                        name: "Алергія на тварин препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/allergija\/allergiya-na-zhivotnykh\/",
                        childrens: []
                    },
                    {
                        id: 264,
                        name: "Алергія на пил препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/allergija\/allergiya-na-pyl\/",
                        childrens: []
                    },
                    {
                        id: 265,
                        name: "Алергія на пилок препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/allergija\/allergiya-na-pyltsu\/",
                        childrens: []
                    },
                    {
                        id: 266,
                        name: "Засоби від алергії на медпрепарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/allergija\/allergiya-na-medpreparaty\/",
                        childrens: []
                    },
                    {
                        id: 267,
                        name: "Харчова алергія препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/allergija\/pishchevaya-allergiya\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 19,
                name: "Знеболюючі засоби",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/boleutoljajushtie-sredstva\/",
                childrens: [
                    {
                        id: 236,
                        name: "Знеболюючі препарати від головного болю",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/boleutoljajushtie-sredstva\/ot-golovnoy-boli\/",
                        childrens: []
                    },
                    {
                        id: 237,
                        name: "Знеболюючі препарати від зубного болю",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/boleutoljajushtie-sredstva\/ot-zubnoy-boli\/",
                        childrens: []
                    },
                    {
                        id: 238,
                        name: "Знеболюючі препарати від болю у спині",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/boleutoljajushtie-sredstva\/ot-boli-v-spine\/",
                        childrens: []
                    },
                    {
                        id: 239,
                        name: "Знеболюючі препарати від суглобового болю",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/boleutoljajushtie-sredstva\/ot-sustavnoy-boli\/",
                        childrens: []
                    },
                    {
                        id: 240,
                        name: "Знеболюючі препарати від менструального болю",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/boleutoljajushtie-sredstva\/ot-menstrualnoy-boli\/",
                        childrens: []
                    },
                    {
                        id: 241,
                        name: "Знеболюючі препарати від спазмів",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/boleutoljajushtie-sredstva\/ot-spazmov\/",
                        childrens: []
                    },
                    {
                        id: 242,
                        name: "Знеболюючі препарати від м'язового болю",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/boleutoljajushtie-sredstva\/ot-myshechnoy-boli\/",
                        childrens: []
                    },
                    {
                        id: 243,
                        name: "Знеболюючі препарати від мігрені",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/boleutoljajushtie-sredstva\/ot-migreni\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 20,
                name: "Гормональні препарати",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/gormonaljnye-preparaty\/",
                childrens: [
                    {
                        id: 303,
                        name: "Препарати при гіпертиреозі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gormonaljnye-preparaty\/pri-gipertireoze\/",
                        childrens: []
                    },
                    {
                        id: 304,
                        name: "Препарати при гіпотиреозі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gormonaljnye-preparaty\/pri-gipotireoze\/",
                        childrens: []
                    },
                    {
                        id: 305,
                        name: "Препарати для схуднення",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gormonaljnye-preparaty\/pri-ozhirenii\/",
                        childrens: []
                    },
                    {
                        id: 306,
                        name: "Гормональні контрацептиви",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gormonaljnye-preparaty\/gormonalnye-kontratseptivy\/",
                        childrens: []
                    },
                    {
                        id: 307,
                        name: "Інсулін",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gormonaljnye-preparaty\/insulin\/",
                        childrens: []
                    },
                    {
                        id: 413,
                        name: "Гормональні мазі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gormonaljnye-preparaty\/gormonalnye-mazi\/",
                        childrens: []
                    },
                    {
                        id: 414,
                        name: "Гормони щитовидної залози",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gormonaljnye-preparaty\/gormony-shchitovidnoy-zhelezy\/",
                        childrens: []
                    },
                    {
                        id: 415,
                        name: "Препарати йоду",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gormonaljnye-preparaty\/preparaty-yoda\/",
                        childrens: []
                    },
                    {
                        id: 416,
                        name: "Антитиреоїдні засоби",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gormonaljnye-preparaty\/antitireoidnye-sredstva\/",
                        childrens: []
                    },
                    {
                        id: 417,
                        name: "Гормональні препарати при клімаксі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gormonaljnye-preparaty\/gormonalnye-preparaty-pri-klimakse\/",
                        childrens: []
                    },
                    {
                        id: 418,
                        name: "Гормональні препарати від алергії",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gormonaljnye-preparaty\/gormonalnoe-lechenie-allergii\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 25,
                name: "Сечогінні засоби",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/mochegonnye-sredstva\/",
                childrens: [
                    {
                        id: 526,
                        name: "Сечогінні засоби для схуднення",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/mochegonnye-sredstva\/mochegonnoe-dlya-pokhudeniya\/",
                        childrens: []
                    },
                    {
                        id: 572,
                        name: "Сечогінні засоби при набряках ніг",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/mochegonnye-sredstva\/mochegonnye-sredstva-pri-otekakh-nog\/",
                        childrens: []
                    },
                    {
                        id: 573,
                        name: "Сечогінні засоби при набряках обличчя",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/mochegonnye-sredstva\/mochegonnye-sredstva-pri-otekakh-litsa\/",
                        childrens: []
                    },
                    {
                        id: 574,
                        name: "Сечогінні препарати при гіпертонії",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/mochegonnye-sredstva\/mochegonnye-preparaty-pri-gipertonii\/",
                        childrens: []
                    },
                    {
                        id: 575,
                        name: "Сечогінні засоби при вагітності",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/mochegonnye-sredstva\/mochegonnye-sredstva-pri-beremennosti\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 26,
                name: "Противірусні препарати",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/protivovirusnye-sredstva\/",
                childrens: [
                    {
                        id: 338,
                        name: "Ліки від грипу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivovirusnye-sredstva\/lechenie-grippa\/",
                        childrens: []
                    },
                    {
                        id: 339,
                        name: "Препарати від кору",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivovirusnye-sredstva\/lechenie-kori\/",
                        childrens: []
                    },
                    {
                        id: 340,
                        name: "Препарати від герпесу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivovirusnye-sredstva\/lechenie-gerpesa\/",
                        childrens: []
                    },
                    {
                        id: 341,
                        name: "Препарати від вітряної віспи",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivovirusnye-sredstva\/lechenie-vetryanoy-ospy\/",
                        childrens: []
                    },
                    {
                        id: 342,
                        name: "Препарати від ВІЛ\/СНІД",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivovirusnye-sredstva\/lechenie-vich-spid\/",
                        childrens: []
                    },
                    {
                        id: 343,
                        name: "Препарати від розсіяного склерозу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivovirusnye-sredstva\/lechenie-rasseyannogo-skleroza\/",
                        childrens: []
                    },
                    {
                        id: 344,
                        name: "Лікування онковірусів",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivovirusnye-sredstva\/lechenie-onkovirusov\/",
                        childrens: []
                    },
                    {
                        id: 345,
                        name: "Препарати від енцефаліту",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivovirusnye-sredstva\/lechenie-entsefalita\/",
                        childrens: []
                    },
                    {
                        id: 346,
                        name: "Лікування ротавірусної інфекції",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivovirusnye-sredstva\/lechenie-rotavirusnoy-infektsii\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 34,
                name: "Препарати для серцево-судинної системи",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/",
                childrens: [
                    {
                        id: 268,
                        name: "Серцева недостатність препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/serdechnaya-nedostatochnost\/",
                        childrens: []
                    },
                    {
                        id: 269,
                        name: "Ішемічна хвороба серця препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/ishemicheskaya-bolezn-serdtsa\/",
                        childrens: []
                    },
                    {
                        id: 270,
                        name: "Інфаркт міокарда препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/infarkt-miokarda\/",
                        childrens: []
                    },
                    {
                        id: 271,
                        name: "Препарати від аритмії",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/aritmiya\/",
                        childrens: []
                    },
                    {
                        id: 272,
                        name: "Гіпертонічна хвороба препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/gipertonicheskaya-bolezn\/",
                        childrens: []
                    },
                    {
                        id: 273,
                        name: "Препарати від стенокардії",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/stenokardiya\/",
                        childrens: []
                    },
                    {
                        id: 274,
                        name: "Артеріальна гіпотензія препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/gipotoniya\/",
                        childrens: []
                    },
                    {
                        id: 275,
                        name: "Препарати від варикозу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/varikoz\/",
                        childrens: []
                    },
                    {
                        id: 276,
                        name: "Препарати від тромбозу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/tromboz\/",
                        childrens: []
                    },
                    {
                        id: 277,
                        name: "Препарати від тахікардії",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/takhikardiya\/",
                        childrens: []
                    },
                    {
                        id: 278,
                        name: "Препарати від болю у серці",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/ot-boli-v-serdtse\/",
                        childrens: []
                    },
                    {
                        id: 350,
                        name: "Сечогінні препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/mochegonnye-preparaty\/",
                        childrens: []
                    },
                    {
                        id: 351,
                        name: "Препарати для зміцнення судин",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/preparaty-dlya-ukrepleniya-sosudov\/",
                        childrens: []
                    },
                    {
                        id: 352,
                        name: "Кровоспинні препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/krovoostanavlivayushchie-preparaty\/",
                        childrens: []
                    },
                    {
                        id: 353,
                        name: "Препарати для розрідження крові",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/preparaty-dlya-razzhizheniya-krovi\/",
                        childrens: []
                    },
                    {
                        id: 354,
                        name: "Препарати для зниження холестерину",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/preparaty-dlya-snizheniya-kholesterina\/",
                        childrens: []
                    },
                    {
                        id: 355,
                        name: "Препарати для покращення мозкового кровообігу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/preparaty-dlya-uluchsheniya-mozgovogo-krovoobrashcheniya\/",
                        childrens: []
                    },
                    {
                        id: 356,
                        name: "Препарати від атеросклерозу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/ateroskleroz\/",
                        childrens: []
                    },
                    {
                        id: 357,
                        name: "Препарати проти геморою",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/gemorroy\/",
                        childrens: []
                    },
                    {
                        id: 358,
                        name: "Препарати від запалення серцевого м'яза",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/vospalenie-serdechnoy-myshtsy\/",
                        childrens: []
                    },
                    {
                        id: 359,
                        name: "Ліки від пороку серця",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/serdechno-sosudistaja-sistema\/porok-serdtsa\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 38,
                name: "Препарати для нервової системи",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/",
                childrens: [
                    {
                        id: 289,
                        name: "Препарати при неврозах",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/pri-nevrozakh\/",
                        childrens: []
                    },
                    {
                        id: 290,
                        name: "Препарати від депресії",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/pri-depressii\/",
                        childrens: []
                    },
                    {
                        id: 291,
                        name: "Засоби при хворобі Паркінсона",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/pri-parkinsonizme\/",
                        childrens: []
                    },
                    {
                        id: 292,
                        name: "Препарати при епілепсії",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/pri-epilepsii\/",
                        childrens: []
                    },
                    {
                        id: 293,
                        name: "Препарати від наркоманії",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/ot-narkomanii\/",
                        childrens: []
                    },
                    {
                        id: 294,
                        name: "Препарати від алкоголізму",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/ot-alkogolizma\/",
                        childrens: []
                    },
                    {
                        id: 295,
                        name: "Препарати від тютюнопаління",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/ot-tabakokureniya\/",
                        childrens: []
                    },
                    {
                        id: 296,
                        name: "Препарати при вегето-судинній дистонії",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/pri-vegeto-sosudistoy-distonii\/",
                        childrens: []
                    },
                    {
                        id: 298,
                        name: "Препарати при деменції",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/pri-dementsii\/",
                        childrens: []
                    },
                    {
                        id: 299,
                        name: "Препарати при стресі та порушення адаптації",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/pri-stresse-i-narusheniyakh-adaptatsii\/",
                        childrens: []
                    },
                    {
                        id: 300,
                        name: "Препарати при психосоматичних розладах",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/pri-psikho-somaticheskikh-rasstroystvakh\/",
                        childrens: []
                    },
                    {
                        id: 301,
                        name: "Препарати при розладах особистості",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/pri-rasstroystvakh-lichnosti\/",
                        childrens: []
                    },
                    {
                        id: 592,
                        name: "Заспокійливі засоби для дітей ",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/uspokoitelnoe-dlya-detey-\/",
                        childrens: []
                    },
                    {
                        id: 379,
                        name: "Препарати від ігрової залежності",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/igrovaya-zavisimost\/",
                        childrens: []
                    },
                    {
                        id: 380,
                        name: "Препарати при запамороченні",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/pri-golovokruzhenii\/",
                        childrens: []
                    },
                    {
                        id: 381,
                        name: "Протисудомні препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/protivosudorozhnye-preparaty\/",
                        childrens: []
                    },
                    {
                        id: 383,
                        name: "Препарати при порушенні сну",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/pri-narushenii-sna\/",
                        childrens: []
                    },
                    {
                        id: 384,
                        name: "Заспокійливі засоби",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/uspokoitelnye-sredstva\/",
                        childrens: []
                    },
                    {
                        id: 385,
                        name: "Ноотропні препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/nootropnye-preparaty\/",
                        childrens: []
                    },
                    {
                        id: 386,
                        name: "Панічні атаки та фобії препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/panicheskie-ataki-i-fobii\/",
                        childrens: []
                    },
                    {
                        id: 387,
                        name: "Препарати від мігрені",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/lechenie-migreni\/",
                        childrens: []
                    },
                    {
                        id: 388,
                        name: "Препарати при черепно-мозкових травмах",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/pri-cherepno-mozgovykh-travmakh\/",
                        childrens: []
                    },
                    {
                        id: 389,
                        name: "Препарати при захворюванні на ДЦП",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/zabolevanie-dtsp\/",
                        childrens: []
                    },
                    {
                        id: 390,
                        name: "Препарати при хворобі Альцгеймера",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/pri-bolezni-altsgeymera\/",
                        childrens: []
                    },
                    {
                        id: 391,
                        name: "Нервова анорексія та булімія препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/nervnaya-anoreksiya-i-bulimiya\/",
                        childrens: []
                    },
                    {
                        id: 392,
                        name: "Препарати від післяпологової депресії",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/nervnaja-sistema\/poslerodovaya-depressiya\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 235,
                name: "Імуномодулятори",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/immunomodulyatory\/",
                childrens: [
                    {
                        id: 22,
                        name: "Імунодепресанти",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/immunomodulyatory\/immunodepressanty\/",
                        childrens: []
                    },
                    {
                        id: 23,
                        name: "Імуностимулятори",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/immunomodulyatory\/immunostimuljatory\/",
                        childrens: []
                    },
                    {
                        id: 402,
                        name: "Інтерферони",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/immunomodulyatory\/interferony\/",
                        childrens: []
                    },
                    {
                        id: 403,
                        name: "Імуноглобуліни",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/immunomodulyatory\/immunoglobuliny\/",
                        childrens: []
                    },
                    {
                        id: 404,
                        name: "Препарати вітаміну С",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/immunomodulyatory\/preparaty-vitamina-s-\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 11,
                name: "Гематологічні препарати",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/sredstva-dlja-sistemy-krovi-i-gemopoeza\/",
                childrens: [
                    {
                        id: 76,
                        name: "Антикоагулянти препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/sredstva-dlja-sistemy-krovi-i-gemopoeza\/gematologicheskie-sredstva\/",
                        childrens: []
                    },
                    {
                        id: 77,
                        name: "Кровезамінники та перфузійні розчини",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/sredstva-dlja-sistemy-krovi-i-gemopoeza\/krovezameniteli-i-perfuzionnie-rastvori\/",
                        childrens: []
                    },
                    {
                        id: 78,
                        name: "Антитромботичні засоби",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/sredstva-dlja-sistemy-krovi-i-gemopoeza\/antitrombicheskie-sredstva\/",
                        childrens: []
                    },
                    {
                        id: 79,
                        name: "Антианемічні засоби",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/sredstva-dlja-sistemy-krovi-i-gemopoeza\/antianemicheskie-sredstva\/",
                        childrens: []
                    },
                    {
                        id: 360,
                        name: "Препарати заліза",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/sredstva-dlja-sistemy-krovi-i-gemopoeza\/preparaty-zheleza-\/",
                        childrens: []
                    },
                    {
                        id: 173,
                        name: "Засоби від варикозу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/sredstva-dlja-sistemy-krovi-i-gemopoeza\/sredstva-ot-varikoza\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 43,
                name: "Урологічні препарати",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/urologija\/",
                childrens: [
                    {
                        id: 309,
                        name: "Препарати при уретриті",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/urologija\/pri-uretrite\/",
                        childrens: []
                    },
                    {
                        id: 310,
                        name: "Препарати при пієлонефриті",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/urologija\/pri-pielonefrite\/",
                        childrens: []
                    },
                    {
                        id: 311,
                        name: "Препарати при сечокам'яній хворобі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/urologija\/pri-mochekamennoy-bolezni\/",
                        childrens: []
                    },
                    {
                        id: 312,
                        name: "Препарати при нетриманні сечі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/urologija\/pri-nederzhanii-mochi\/",
                        childrens: []
                    },
                    {
                        id: 313,
                        name: "Препарати при аденомі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/urologija\/pri-adenome\/",
                        childrens: []
                    },
                    {
                        id: 314,
                        name: "Препарати при простатиті",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/urologija\/pri-prostatite\/",
                        childrens: []
                    },
                    {
                        id: 315,
                        name: "Препарати для потенції",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/urologija\/pri-impotentsii\/",
                        childrens: []
                    },
                    {
                        id: 376,
                        name: "Ліки при затримці сечовипускання",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/urologija\/zaderzhka-mocheispuskaniya\/",
                        childrens: []
                    },
                    {
                        id: 377,
                        name: "Препарати для лікування циститу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/urologija\/lechenie-tsistita\/",
                        childrens: []
                    },
                    {
                        id: 378,
                        name: "Препарати при болях у нирках",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/urologija\/preparaty-pri-bolyakh-v-pochkakh\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 47,
                name: "Гепатопротектори",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/gepatoprotektory\/",
                childrens: [
                    {
                        id: 370,
                        name: "Препарати для лікування жовчовивідних шляхів",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gepatoprotektory\/dlya-lecheniya-zhelchevyvodyashchikh-putey\/",
                        childrens: []
                    },
                    {
                        id: 371,
                        name: "Препарати для лікування захворювання печінки",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gepatoprotektory\/dlya-lecheniya-zabolevaniya-pecheni\/",
                        childrens: []
                    },
                    {
                        id: 372,
                        name: "Препарати при лікуванні гепатиту C",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gepatoprotektory\/dlya-lecheniya-gepatita-c\/",
                        childrens: []
                    },
                    {
                        id: 1140,
                        name: "Препарати при лікуванні гепатиту B",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gepatoprotektory\/preparaty-pri-lechenii-gepatita-b\/",
                        childrens: []
                    },
                    {
                        id: 373,
                        name: "Жовчогінні препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gepatoprotektory\/zhelchegonnye\/",
                        childrens: []
                    },
                    {
                        id: 374,
                        name: "Амінокислоти",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/gepatoprotektory\/aminokisloty\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 37,
                name: "Препарати для шлунково-кишкового тракту",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/zheludochno-kishechnyj-trakt\/",
                childrens: [
                    {
                        id: 325,
                        name: "Препарати від гастриту",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/zheludochno-kishechnyj-trakt\/lechenie-gastrita\/",
                        childrens: []
                    },
                    {
                        id: 326,
                        name: "Препарати від панкреатиту",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/zheludochno-kishechnyj-trakt\/lechenie-pankreatita\/",
                        childrens: []
                    },
                    {
                        id: 327,
                        name: "Препарати від геморою",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/zheludochno-kishechnyj-trakt\/lechenie-gemorroya\/",
                        childrens: []
                    },
                    {
                        id: 328,
                        name: "Препарати від дисбактеріозу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/zheludochno-kishechnyj-trakt\/lechenie-disbakterioza\/",
                        childrens: []
                    },
                    {
                        id: 329,
                        name: "Препарати від печії",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/zheludochno-kishechnyj-trakt\/lechenie-izzhogi\/",
                        childrens: []
                    },
                    {
                        id: 330,
                        name: "Препарати від коліту",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/zheludochno-kishechnyj-trakt\/lechenie-kolita\/",
                        childrens: []
                    },
                    {
                        id: 331,
                        name: "Препарати від ентериту",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/zheludochno-kishechnyj-trakt\/lechenie-enterita\/",
                        childrens: []
                    },
                    {
                        id: 333,
                        name: "Препарати від дуоденіту",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/zheludochno-kishechnyj-trakt\/lechenie-duodenita\/",
                        childrens: []
                    },
                    {
                        id: 334,
                        name: "Препарати від холециститу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/zheludochno-kishechnyj-trakt\/lechenie-kholetsistita\/",
                        childrens: []
                    },
                    {
                        id: 335,
                        name: "Препарати від виразкової хвороби",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/zheludochno-kishechnyj-trakt\/lechenie-yazvennoy-bolezni\/",
                        childrens: []
                    },
                    {
                        id: 337,
                        name: "Препарати від здуття",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/zheludochno-kishechnyj-trakt\/lechenie-vzdutiya\/",
                        childrens: []
                    },
                    {
                        id: 419,
                        name: "Ферменти для покращення травлення",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/zheludochno-kishechnyj-trakt\/fermenty-dlya-uluchsheniya-pishchevareniya\/",
                        childrens: []
                    },
                    {
                        id: 420,
                        name: "Препарати від гастродуоденіту",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/zheludochno-kishechnyj-trakt\/lechenie-gastroduodenita\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 31,
                name: "Препарати від хвороби Паркінсона",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/protivoparkinsonicheskie-sredstva\/",
                childrens: []
            },
            {
                id: 32,
                name: "Препарати від епілепсії",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/protivoepilepticheskie-sredstva\/",
                childrens: []
            },
            {
                id: 41,
                name: "Препарати від псоріазу",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/psoriaz\/",
                childrens: []
            },
            {
                id: 45,
                name: "Протиревматичні",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/protivorevmaticheskie\/",
                childrens: []
            },
            {
                id: 30,
                name: "Засоби від паразитів",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/protivoparazitarnye-sredstva-1\/",
                childrens: [
                    {
                        id: 524,
                        name: "Препарати від глистів",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivoparazitarnye-sredstva-1\/preparaty-ot-glistov\/",
                        childrens: []
                    },
                    {
                        id: 525,
                        name: "Препарати від лямблій",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivoparazitarnye-sredstva-1\/preparaty-ot-lyambliy\/",
                        childrens: []
                    },
                    {
                        id: 567,
                        name: "Ліки від гостриків",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivoparazitarnye-sredstva-1\/lekarstva-ot-ostrits-\/",
                        childrens: []
                    },
                    {
                        id: 568,
                        name: "Ліки від аскарид",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivoparazitarnye-sredstva-1\/lekarstvo-ot-askarid-\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 35,
                name: "Препарати для опорно-рухового апарату",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/",
                childrens: [
                    {
                        id: 279,
                        name: "Препарати при артриті",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/pri-artrite\/",
                        childrens: []
                    },
                    {
                        id: 280,
                        name: "Препарати при артрозі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/pri-artroze\/",
                        childrens: []
                    },
                    {
                        id: 281,
                        name: "Препарати при бурсіті",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/pri-bursite\/",
                        childrens: []
                    },
                    {
                        id: 282,
                        name: "Препарати при міозиті",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/pri-miozite\/",
                        childrens: []
                    },
                    {
                        id: 283,
                        name: "Препарати при остеопорозі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/pri-osteoporoze\/",
                        childrens: []
                    },
                    {
                        id: 284,
                        name: "Препарати при періартриті",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/pri-periartrite\/",
                        childrens: []
                    },
                    {
                        id: 285,
                        name: "Препарати при радикуліті",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/pri-radikulite\/",
                        childrens: []
                    },
                    {
                        id: 287,
                        name: "Препарати при ревматизмі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/pri-revmatizme\/",
                        childrens: []
                    },
                    {
                        id: 288,
                        name: "Знеболювальні мазі для опорно-рухового апарату",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/obezbolivayushchie-mazi\/",
                        childrens: []
                    },
                    {
                        id: 393,
                        name: "Препарати кальцію",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/preparaty-kaltsiya\/",
                        childrens: []
                    },
                    {
                        id: 394,
                        name: "Препарати від захворювання кісток",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/zabolevaniya-kostey\/",
                        childrens: []
                    },
                    {
                        id: 395,
                        name: "Хондропротектори",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/khondroprotektory\/",
                        childrens: []
                    },
                    {
                        id: 396,
                        name: "Міорелаксанти",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/miorelaksanty\/",
                        childrens: []
                    },
                    {
                        id: 397,
                        name: "Протизапальні препарати",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/protivospalitelnye-preparaty\/",
                        childrens: []
                    },
                    {
                        id: 398,
                        name: "Препарати від спондильозу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/lechenie-spondileza\/",
                        childrens: []
                    },
                    {
                        id: 399,
                        name: "Препарати від подагри",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/lechenie-podagry\/",
                        childrens: []
                    },
                    {
                        id: 400,
                        name: "Препарати від розтягування зв'язок",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/rastyazhenie-svyazok\/",
                        childrens: []
                    },
                    {
                        id: 401,
                        name: "Препарати від сколіозу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/oporno-dvigateljnyj-apparat\/lechenie-skolioza\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 40,
                name: "Препарати для підвищення апетиту",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/povyshenie-appetita\/",
                childrens: []
            },
            {
                id: 53,
                name: "Гомеопатичні препарати",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/gomeopaticheskie-sredstva\/",
                childrens: []
            },
            {
                id: 12,
                name: "Лікування порожнини рота",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/sredstva-stomatologii\/",
                childrens: [
                    {
                        id: 569,
                        name: "Ліки від стоматиту",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/sredstva-stomatologii\/lekarstva-ot-stomatita-\/",
                        childrens: []
                    },
                    {
                        id: 570,
                        name: "Препарати від пародонтозу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/sredstva-stomatologii\/preparaty-ot-parodontoza\/",
                        childrens: []
                    },
                    {
                        id: 571,
                        name: "Препарати при пульпіті",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/sredstva-stomatologii\/preparaty-pri-pulpite-\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 46,
                name: "Препарати від туберкульозу",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/tuberkulez\/",
                childrens: []
            },
            {
                id: 56,
                name: "Лікувальні трави",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/lekarstvennye-travy\/",
                childrens: [
                    {
                        id: 594,
                        name: "Трав'яний чай",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/lekarstvennye-travy\/travyanoy-chay\/",
                        childrens: []
                    },
                    {
                        id: 595,
                        name: "Фіточай",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/lekarstvennye-travy\/fitochay\/",
                        childrens: []
                    },
                    {
                        id: 596,
                        name: "Чай для схуднення",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/lekarstvennye-travy\/chay-dlya-pokhudeniya\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 18,
                name: "Засоби для лікування очей",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/glaznye-sredstva\/",
                childrens: [
                    {
                        id: 527,
                        name: "Краплі від кон'юнктивіту",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/glaznye-sredstva\/kapli-ot-konyuktivita\/",
                        childrens: []
                    },
                    {
                        id: 579,
                        name: "Краплі при глаукомі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/glaznye-sredstva\/kapli-pri-glaukome-\/",
                        childrens: []
                    },
                    {
                        id: 580,
                        name: "Краплі від катаракти",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/glaznye-sredstva\/kapli-ot-katarakty-\/",
                        childrens: []
                    },
                    {
                        id: 582,
                        name: "Краплі від сухості очей",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/glaznye-sredstva\/kapli-ot-sukhosti-glaz-\/",
                        childrens: []
                    },
                    {
                        id: 583,
                        name: "Краплі від ячменю",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/glaznye-sredstva\/kapli-ot-yachmenya-\/",
                        childrens: []
                    },
                    {
                        id: 585,
                        name: "Краплі для очей",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/glaznye-sredstva\/kapli-dlya-glaz-\/",
                        childrens: []
                    },
                    {
                        id: 586,
                        name: "Мазь для очей",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/glaznye-sredstva\/maz-dlya-glaz-\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 36,
                name: "Лікування геморою",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/lechenie-gemorroja\/",
                childrens: [
                    {
                        id: 410,
                        name: "Свічки від геморою",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/lechenie-gemorroja\/svechi-ot-gemorroya\/",
                        childrens: []
                    },
                    {
                        id: 411,
                        name: "Мазі від геморою",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/lechenie-gemorroja\/mazi-ot-gemorroya\/",
                        childrens: []
                    },
                    {
                        id: 412,
                        name: "Допоміжні засоби від геморою",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/lechenie-gemorroja\/vspomogatelnye-sredstva-ot-gemorroya\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 49,
                name: "Препарати від захворювання вух",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/otologija\/",
                childrens: [
                    {
                        id: 616,
                        name: "Краплі при отіті",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/otologija\/kapli-pri-otite\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 24,
                name: "Анестезуючі засоби",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/anestezirujushtie-sredstva\/",
                childrens: []
            },
            {
                id: 39,
                name: "Препарати від діабету",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/diabet\/",
                childrens: [
                    {
                        id: 588,
                        name: "Препарати при діабеті 2 типу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/diabet\/preparaty-pri-diabete-2-tipa-\/",
                        childrens: []
                    },
                    {
                        id: 589,
                        name: "Препарати при діабеті 1 типу",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/diabet\/preparaty-pri-diabete-1-tipa-\/",
                        childrens: []
                    },
                    {
                        id: 421,
                        name: "Препарати для зниження цукру в крові",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/diabet\/preparaty-dlya-snizheniya-sakhara-v-krovi\/",
                        childrens: []
                    },
                    {
                        id: 422,
                        name: "Допоміжні засоби від діабету",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/diabet\/vspomogatelnye-sredstva-ot-diabeta\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 17,
                name: "Вакцини",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/vakciny\/",
                childrens: []
            },
            {
                id: 28,
                name: "Протимікробні засоби",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/protivomikrobnye-sredstva\/",
                childrens: [
                    {
                        id: 535,
                        name: "Антибактеріальні свічки",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivomikrobnye-sredstva\/antibakterialnye-svechi\/",
                        childrens: []
                    },
                    {
                        id: 590,
                        name: "Протимікробні мазі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivomikrobnye-sredstva\/protivomikrobnye-mazi-\/",
                        childrens: []
                    },
                    {
                        id: 591,
                        name: "Антибактеріальні краплі",
                        url: "https:\/\/www.apteka24.ua\/medikamenty\/protivomikrobnye-sredstva\/antibakterialnye-kapli\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 29,
                name: "Протипухлинні засоби",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/protivoopuholevye-sredstva\/",
                childrens: []
            },
            {
                id: 44,
                name: "Сироватки та імуноглобуліни",
                url: "https:\/\/www.apteka24.ua\/medikamenty\/syvorotki-i-immunoglobuliny\/",
                childrens: []
            }
        ]
    },
    {
        id: 1144,
        name: "Продукція Coloplast",
        url: "https:\/\/www.apteka24.ua\/uk\/search?query=coloplast",
        icon: `${env.getMainImageRepository()}/icons/menu/Coloplast.svg`,
        promo: {
            url: "",
            preview: "@Image"
        },
        popular: {
            title: "Популярні категорії",
            items: []
        },
        subItems: []
    },
    {
        id: 52,
        name: "БАД та вітаміни",
        url: "https:\/\/www.apteka24.ua\/bad\/",
        icon: `${env.getMainImageRepository()}/icons/menu/Supplements.svg`,
        promo: {
            url: "",
            preview: "@Image"
        },
        popular: {
            title: "Популярні категорії",
            items: []
        },
        subItems: [
            {
                id: 145,
                name: "Пивні дріжджі",
                url: "https:\/\/www.apteka24.ua\/bad\/drozhzhi-pivnye\/",
                childrens: []
            },
            {
                id: 146,
                name: "Поживні продукти",
                url: "https:\/\/www.apteka24.ua\/bad\/pitatel-nie-produkti\/",
                childrens: []
            },
            {
                id: 147,
                name: "Мінеральні добавки",
                url: "https:\/\/www.apteka24.ua\/bad\/mineral-nie-dobavki\/",
                childrens: []
            },
            {
                id: 148,
                name: "Тонізуючі препарати",
                url: "https:\/\/www.apteka24.ua\/bad\/tonizirujushtie-preparaty\/",
                childrens: []
            },
            {
                id: 149,
                name: "Вітаміни загальні",
                url: "https:\/\/www.apteka24.ua\/bad\/vitamini-obshie\/",
                childrens: []
            },
            {
                id: 150,
                name: "Вітаміни для діабетиків",
                url: "https:\/\/www.apteka24.ua\/bad\/dlya-diabetikov\/",
                childrens: []
            },
            {
                id: 151,
                name: "Вітаміни для дієти та схуднення",
                url: "https:\/\/www.apteka24.ua\/bad\/dlya-diety-i-pokhudeniya\/",
                childrens: []
            },
            {
                id: 152,
                name: "Сорбенти та детоксиканти",
                url: "https:\/\/www.apteka24.ua\/bad\/sorbenty-i-detoksikanty\/",
                childrens: []
            },
            {
                id: 153,
                name: "Риб'ячий жир та Омега-3",
                url: "https:\/\/www.apteka24.ua\/bad\/rybiy-zhir-i-omega-3\/",
                childrens: []
            },
            {
                id: 154,
                name: "Вітаміни для очей",
                url: "https:\/\/www.apteka24.ua\/bad\/vitaminy-dlya-glaz\/",
                childrens: []
            },
            {
                id: 155,
                name: "Вітаміни для дітей",
                url: "https:\/\/www.apteka24.ua\/bad\/vitaminy-dlya-detey\/",
                childrens: []
            },
            {
                id: 156,
                name: "Вітаміни для вагітних та годуючих",
                url: "https:\/\/www.apteka24.ua\/bad\/vitaminy-dlya-zhenshchin\/",
                childrens: []
            },
            {
                id: 157,
                name: "Вітаміни для краси",
                url: "https:\/\/www.apteka24.ua\/bad\/vitaminy-dlya-krasoty\/",
                childrens: []
            },
            {
                id: 159,
                name: "Закваски",
                url: "https:\/\/www.apteka24.ua\/bad\/zakvaski\/",
                childrens: []
            },
            {
                id: 160,
                name: "БАДи для травлення",
                url: "https:\/\/www.apteka24.ua\/bad\/bady-dlya-pishchevareniya\/",
                childrens: []
            },
            {
                id: 161,
                name: "БАДи для серця та судин",
                url: "https:\/\/www.apteka24.ua\/bad\/bady-dlya-serdtsa-i-sosudov\/",
                childrens: []
            },
            {
                id: 162,
                name: "БАДи для щитовидної залози",
                url: "https:\/\/www.apteka24.ua\/bad\/bady-dlya-shchitovidnoy-zhelezy\/",
                childrens: []
            },
            {
                id: 163,
                name: "БАДи для органів дихання",
                url: "https:\/\/www.apteka24.ua\/bad\/bady-dlya-organov-dykhaniya\/",
                childrens: []
            },
            {
                id: 164,
                name: "БАДи для суглобів",
                url: "https:\/\/www.apteka24.ua\/bad\/bady-dlya-sustavov\/",
                childrens: []
            },
            {
                id: 165,
                name: "БАДи для жіночого здоров'я",
                url: "https:\/\/www.apteka24.ua\/bad\/bady-dlya-zhenskogo-zdorovya\/",
                childrens: [
                    {
                        id: 509,
                        name: "Вітаміни та мінерали для жінок",
                        url: "https:\/\/www.apteka24.ua\/bad\/bady-dlya-zhenskogo-zdorovya\/vitaminy-i-mineraly-dlya-zhenshchin\/",
                        childrens: []
                    },
                    {
                        id: 511,
                        name: "Препарати, що впливають на жіночий гормональний фон.",
                        url: "https:\/\/www.apteka24.ua\/bad\/bady-dlya-zhenskogo-zdorovya\/vliyayushchiy-na-aktivnost-polovykh-gormonov-zhenshchin\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 166,
                name: "БАДи для чоловічого здоров'я",
                url: "https:\/\/www.apteka24.ua\/bad\/bady-dlya-muzhskogo-zdorovya\/",
                childrens: []
            },
            {
                id: 678,
                name: "Фітопрепарати",
                url: "https:\/\/www.apteka24.ua\/bad\/fitopreparaty\/",
                childrens: [
                    {
                        id: 806,
                        name: "Для імунітету та всього організму",
                        url: "https:\/\/www.apteka24.ua\/bad\/fitopreparaty\/fitopreparaty-dlya-immuniteta-i-vsego-organizma\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 167,
                name: "Вітаміни для нервової системи",
                url: "https:\/\/www.apteka24.ua\/bad\/dlya-nervnoy-sistemy\/",
                childrens: []
            },
            {
                id: 168,
                name: "БАДи для сечостатевої системи",
                url: "https:\/\/www.apteka24.ua\/bad\/bady-dlya-mochepolovoy-sistemy\/",
                childrens: [
                    {
                        id: 512,
                        name: "Для лікування та профілактики утворення каменів",
                        url: "https:\/\/www.apteka24.ua\/bad\/bady-dlya-mochepolovoy-sistemy\/dlya-lecheniya-i-profilaktiki-obrazovaniya-kamney\/",
                        childrens: []
                    },
                    {
                        id: 516,
                        name: "Протизапальної дії сечостатевої системи та нирок",
                        url: "https:\/\/www.apteka24.ua\/bad\/bady-dlya-mochepolovoy-sistemy\/protivovospalitelnogo-deystviya-mochepolovoy-sistemy-i-pochek\/",
                        childrens: []
                    },
                    {
                        id: 517,
                        name: "Протизапальну дію органів малого тазу",
                        url: "https:\/\/www.apteka24.ua\/bad\/bady-dlya-mochepolovoy-sistemy\/protivovospalitelnogo-deystviya-organov-malogo-taza\/",
                        childrens: []
                    }
                ]
            }
        ]
    },
    {
        id: 8,
        name: "Медичні товари",
        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/",
        icon: `${env.getMainImageRepository()}/icons/menu/MedsProd.svg`,
        promo: {
            url: "",
            preview: "@Image"
        },
        popular: {
            title: "Популярні категорії",
            items: []
        },
        subItems: [
            {
                id: 1103,
                name: "Медична техніка та прилади",
                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/",
                childrens: [
                    {
                        id: 57,
                        name: "Глюкометри",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/glyukometri\/",
                        childrens: []
                    },
                    {
                        id: 88,
                        name: "Тонометри",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/tonometry\/",
                        childrens: [
                            {
                                id: 456,
                                name: "Тонометри Omron",
                                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/tonometry\/tonometr-omron\/",
                                childrens: []
                            },
                            {
                                id: 988,
                                name: "Тонометри автоматичні Microlife",
                                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/tonometry\/tonometri-avtomatichni-microlife\/",
                                childrens: []
                            },
                            {
                                id: 989,
                                name: "Тонометри механічні Microlife",
                                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/tonometry\/tonometri-mekhanichni-microlife\/",
                                childrens: []
                            },
                            {
                                id: 990,
                                name: "Тонометри напівавтоматичні Microlife",
                                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/tonometry\/tonometri-napivavtomatichni-microlife\/",
                                childrens: []
                            },
                            {
                                id: 991,
                                name: "Автоматичні тонометри Vega",
                                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/tonometry\/tonometri-avtomatichni-vega\/",
                                childrens: []
                            },
                            {
                                id: 992,
                                name: "Тонометри механічні Vega",
                                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/tonometry\/tonometri-mekhanichni-vega\/",
                                childrens: []
                            },
                            {
                                id: 993,
                                name: "Тонометри напівавтоматичні Vega",
                                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/tonometry\/tonometri-napivavtomatichni-vega\/",
                                childrens: []
                            }
                        ]
                    },
                    {
                        id: 94,
                        name: "Інгалятори та небулайзери",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/ingalyatory-i-nebulayzery\/",
                        childrens: [
                            {
                                id: 457,
                                name: "Інгалятор Omron",
                                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/ingalyatory-i-nebulayzery\/ingalyator-omron\/",
                                childrens: []
                            },
                            {
                                id: 984,
                                name: "Небулайзери Microlife",
                                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/ingalyatory-i-nebulayzery\/nebulayzeri-microlife\/",
                                childrens: []
                            },
                            {
                                id: 985,
                                name: "Небулайзери Vega",
                                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/ingalyatory-i-nebulayzery\/nebulayzeri-vega\/",
                                childrens: []
                            }
                        ]
                    },
                    {
                        id: 95,
                        name: "Стетоскопи",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/stetoskopy\/",
                        childrens: []
                    },
                    {
                        id: 58,
                        name: "Термометри",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/termometri\/",
                        childrens: [
                            {
                                id: 986,
                                name: "Термометри електронні Microlife",
                                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/termometri\/termometri-elektronni-microlife\/",
                                childrens: []
                            },
                            {
                                id: 987,
                                name: "Термометри електронні Vega",
                                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-tekhnika-i-pribory\/termometri\/termometr-elektronniy-vega\/",
                                childrens: []
                            }
                        ]
                    }
                ]
            },
            {
                id: 186,
                name: "Вироби медичного призначення",
                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/izdeliya-meditcinskogo-naznacheniya\/",
                childrens: [
                    {
                        id: 557,
                        name: "Гінекологічні набори",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/izdeliya-meditcinskogo-naznacheniya\/ginekologicheskie-nabory\/",
                        childrens: []
                    },
                    {
                        id: 632,
                        name: "Контейнери для аналізу",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/izdeliya-meditcinskogo-naznacheniya\/konteynery-dlya-analizov\/",
                        childrens: []
                    },
                    {
                        id: 1120,
                        name: "Грілки",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/izdeliya-meditcinskogo-naznacheniya\/grelki\/",
                        childrens: []
                    },
                    {
                        id: 1121,
                        name: "Масажери та банки для масажу",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/izdeliya-meditcinskogo-naznacheniya\/massazhery-i-banki-dlya-massazha\/",
                        childrens: []
                    },
                    {
                        id: 1122,
                        name: "Плівка для рентгену",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/izdeliya-meditcinskogo-naznacheniya\/plenka-dlya-rentgena\/",
                        childrens: []
                    },
                    {
                        id: 1123,
                        name: "Покриття операційні",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/izdeliya-meditcinskogo-naznacheniya\/pokrytiya-operatsionnye\/",
                        childrens: []
                    },
                    {
                        id: 710,
                        name: "Протези для грудей",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/izdeliya-meditcinskogo-naznacheniya\/protezy-dlya-grudi\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 707,
                name: "Бандажі",
                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/korsetno-bandazhnaya-produktsiya\/",
                childrens: [
                    {
                        id: 731,
                        name: "Бандажі для шиї",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/korsetno-bandazhnaya-produktsiya\/bandazh-na-sheynyy-otdel\/",
                        childrens: []
                    },
                    {
                        id: 727,
                        name: "Бандаж на плечовий пояс",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/korsetno-bandazhnaya-produktsiya\/bandazh-na-plechevoy-poyas\/",
                        childrens: []
                    },
                    {
                        id: 724,
                        name: "Бандаж для руки (підтримуючий)",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/korsetno-bandazhnaya-produktsiya\/kosynka-podderzhivayushchiy-bandazh\/",
                        childrens: []
                    },
                    {
                        id: 725,
                        name: "Бандажі на ліктьовий суглоб",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/korsetno-bandazhnaya-produktsiya\/bandazhi-na-loktevoy-sustav\/",
                        childrens: []
                    },
                    {
                        id: 726,
                        name: "Бандажі на променево-зап'ястковий суглоб",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/korsetno-bandazhnaya-produktsiya\/bandazhi-na-luchezapyastnyy-sustav\/",
                        childrens: []
                    },
                    {
                        id: 623,
                        name: "Бандаж післяопераційний",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/korsetno-bandazhnaya-produktsiya\/bandazh-posleoperatsionnyy\/",
                        childrens: []
                    },
                    {
                        id: 719,
                        name: "Грижові бандажі",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/korsetno-bandazhnaya-produktsiya\/gryzhevye-bandazhi\/",
                        childrens: []
                    },
                    {
                        id: 715,
                        name: "Бандажі до- післяпологові",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/korsetno-bandazhnaya-produktsiya\/bandazhi-do-poslerodovye\/",
                        childrens: []
                    },
                    {
                        id: 729,
                        name: "Бандажі на кульшовий суглоб",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/korsetno-bandazhnaya-produktsiya\/bandazhi-na-tazobedrennyy-sustav\/",
                        childrens: []
                    },
                    {
                        id: 721,
                        name: "Бандажі на колінний суглоб",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/korsetno-bandazhnaya-produktsiya\/bandazhi-na-kolennyy-sustav\/",
                        childrens: []
                    },
                    {
                        id: 718,
                        name: "Бандажі на гомілковостопний суглоб",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/korsetno-bandazhnaya-produktsiya\/bandazhi-na-golenostopnyy-sustav\/",
                        childrens: []
                    },
                    {
                        id: 717,
                        name: "Вальгусні бандажі",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/korsetno-bandazhnaya-produktsiya\/valgusnye-bandazhi\/",
                        childrens: []
                    },
                    {
                        id: 1109,
                        name: "Еластичні бинти",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/korsetno-bandazhnaya-produktsiya\/elastichnye-binty\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 1104,
                name: "Ортопедичні товари",
                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ortopedicheskie-tovary\/",
                childrens: [
                    {
                        id: 722,
                        name: "Коректори осанки",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ortopedicheskie-tovary\/korrektory-osanki\/",
                        childrens: []
                    },
                    {
                        id: 723,
                        name: "Корсети попереково-хрестові",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ortopedicheskie-tovary\/korsety-poyasnichno-krestsovye\/",
                        childrens: []
                    },
                    {
                        id: 730,
                        name: "Тутор",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ortopedicheskie-tovary\/tutor\/",
                        childrens: []
                    },
                    {
                        id: 708,
                        name: "Взуття ортопедичне",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ortopedicheskie-tovary\/obuv-ortopedicheskaya\/",
                        childrens: []
                    },
                    {
                        id: 711,
                        name: "Устілки ортопедичні",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ortopedicheskie-tovary\/stelki-ortopedicheskie\/",
                        childrens: []
                    },
                    {
                        id: 737,
                        name: "Ортопедичні вкладиші, підп'ятники, роздільники",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ortopedicheskie-tovary\/ortopedicheskie-vkladyshi-podpyatochniki-razdeliteli\/",
                        childrens: []
                    },
                    {
                        id: 739,
                        name: "Напівстілки ортопедичні",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ortopedicheskie-tovary\/polustelki-ortopedicheskie\/",
                        childrens: []
                    },
                    {
                        id: 713,
                        name: "Устілки діабетичні",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ortopedicheskie-tovary\/stelki-diabeticheskie\/",
                        childrens: []
                    },
                    {
                        id: 709,
                        name: "Подушки ортопедичні",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ortopedicheskie-tovary\/podushki-ortopedicheskie\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 701,
                name: "Компресійний трикотаж",
                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/kompressionnyy-trikotazh\/",
                childrens: [
                    {
                        id: 703,
                        name: "Гольфи компресійні",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/kompressionnyy-trikotazh\/golfy\/",
                        childrens: []
                    },
                    {
                        id: 704,
                        name: "Компресійні колготи",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/kompressionnyy-trikotazh\/kolgoty\/",
                        childrens: []
                    },
                    {
                        id: 706,
                        name: "Компресійні панчохи",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/kompressionnyy-trikotazh\/chulki\/",
                        childrens: []
                    },
                    {
                        id: 705,
                        name: "Товари після ліпосакції",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/kompressionnyy-trikotazh\/tovary-posle-liposaktsii\/",
                        childrens: []
                    },
                    {
                        id: 702,
                        name: "Антиемболічний трикотаж",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/kompressionnyy-trikotazh\/antiembolicheskiy-trikotazh\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 1106,
                name: "Маніпуляційні засоби",
                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/manipulyatsionnye-sredstva\/",
                childrens: [
                    {
                        id: 624,
                        name: "Канюля",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/manipulyatsionnye-sredstva\/kanyulya\/",
                        childrens: []
                    },
                    {
                        id: 1124,
                        name: "Катетери",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/manipulyatsionnye-sredstva\/katetery\/",
                        childrens: []
                    },
                    {
                        id: 548,
                        name: "Шприци одноразові",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/manipulyatsionnye-sredstva\/shpritsy-odnorazovye\/",
                        childrens: []
                    },
                    {
                        id: 633,
                        name: "Спринцівки (клізми)",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/manipulyatsionnye-sredstva\/sprintsovka\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 1107,
                name: "Перев'язувальні засоби",
                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/perevyazochnye-sredstva\/",
                childrens: [
                    {
                        id: 555,
                        name: "Вата",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/perevyazochnye-sredstva\/vata\/",
                        childrens: []
                    },
                    {
                        id: 1125,
                        name: "Бінти",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/perevyazochnye-sredstva\/binty\/",
                        childrens: []
                    },
                    {
                        id: 1126,
                        name: "Пластирі",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/perevyazochnye-sredstva\/plastyri\/",
                        childrens: []
                    },
                    {
                        id: 1127,
                        name: "Марля",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/perevyazochnye-sredstva\/marlya\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 1105,
                name: "Догляд за хворими",
                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ukhod-za-bolnymi\/",
                childrens: [
                    {
                        id: 463,
                        name: "Пелюшки для дорослих",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ukhod-za-bolnymi\/pelenki\/",
                        childrens: []
                    },
                    {
                        id: 461,
                        name: "Підгузки для дорослих",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ukhod-za-bolnymi\/podguzniki-dlya-vzroslykh\/",
                        childrens: []
                    },
                    {
                        id: 462,
                        name: "Урологічні прокладки",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ukhod-za-bolnymi\/urologicheskie-prokladki\/",
                        childrens: []
                    },
                    {
                        id: 1110,
                        name: "Калоприймачі",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ukhod-za-bolnymi\/kalopriemniki\/",
                        childrens: []
                    },
                    {
                        id: 1111,
                        name: "Сечоприймачі",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ukhod-za-bolnymi\/mochepriemniki\/",
                        childrens: []
                    },
                    {
                        id: 132,
                        name: "Засоби догляду за стомою",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/ukhod-za-bolnymi\/sredstva-ukhoda-za-stomoy\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 1108,
                name: "Планування сім'ї",
                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/planirovanie-semi\/",
                childrens: [
                    {
                        id: 505,
                        name: "Тести для визначення вагітності",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/planirovanie-semi\/testy-dlya-opredeleniya-beremennosti\/",
                        childrens: []
                    },
                    {
                        id: 1131,
                        name: "Тести для визначення овуляції",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/planirovanie-semi\/testy-dlya-opredeleniya-ovulyatsii\/",
                        childrens: []
                    },
                    {
                        id: 169,
                        name: "Лубриканти (гель-мастила)",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/planirovanie-semi\/lubrikanty-gel-smazki\/",
                        childrens: []
                    },
                    {
                        id: 1132,
                        name: "Презервативи",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/planirovanie-semi\/prezervativy\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 187,
                name: "Діагностичні засоби",
                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/diagnosticheskie-sredstva\/",
                childrens: [
                    {
                        id: 560,
                        name: "Діагностичні тести",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/diagnosticheskie-sredstva\/diagnosticheskie-testy\/",
                        childrens: []
                    },
                    {
                        id: 1133,
                        name: "Ланцети для глюкометра",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/diagnosticheskie-sredstva\/lantsety-dlya-glyukometra\/",
                        childrens: []
                    },
                    {
                        id: 458,
                        name: "Тест-смужки для глюкометра",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/diagnosticheskie-sredstva\/test-poloski-dlya-glyukometra\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 127,
                name: "Медичний одяг",
                url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-odezhda\/",
                childrens: [
                    {
                        id: 636,
                        name: "Маска медична",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-odezhda\/maska-meditsinskaya\/",
                        childrens: []
                    },
                    {
                        id: 634,
                        name: "Халат медичний",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-odezhda\/khalat-meditsinskiy\/",
                        childrens: []
                    },
                    {
                        id: 565,
                        name: "Рукавички медичні",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-odezhda\/perchatki-meditsinskie\/",
                        childrens: []
                    },
                    {
                        id: 635,
                        name: "Бахіли",
                        url: "https:\/\/www.apteka24.ua\/medicinskaja-tehnika\/meditsinskaya-odezhda\/bakhily\/",
                        childrens: []
                    }
                ]
            }
        ]
    },
    {
        id: 15,
        name: "Антибіотики",
        url: "https:\/\/www.apteka24.ua\/antibiotiki\/",
        icon: `${env.getMainImageRepository()}/icons/menu/Antibiotics.svg`,
        promo: {
            url: "",
            preview: "@Image"
        },
        popular: {
            title: "Популярні категорії",
            items: []
        },
        subItems: [
            {
                id: 521,
                name: "Антибіотики при грипі",
                url: "https:\/\/www.apteka24.ua\/antibiotiki\/antibiotiki-pri-grippe\/",
                childrens: []
            },
            {
                id: 244,
                name: "Антибіотики при ангіні",
                url: "https:\/\/www.apteka24.ua\/antibiotiki\/pri-angine\/",
                childrens: []
            },
            {
                id: 245,
                name: "Антибіотики при бронхіті",
                url: "https:\/\/www.apteka24.ua\/antibiotiki\/pri-bronkhite\/",
                childrens: []
            },
            {
                id: 246,
                name: "Антибіотики при отиті",
                url: "https:\/\/www.apteka24.ua\/antibiotiki\/pri-otite\/",
                childrens: []
            },
            {
                id: 247,
                name: "Антибіотики при гаймориті",
                url: "https:\/\/www.apteka24.ua\/antibiotiki\/pri-gaymorite\/",
                childrens: []
            },
            {
                id: 248,
                name: "Антибіотики при циститі",
                url: "https:\/\/www.apteka24.ua\/antibiotiki\/pri-tsistite\/",
                childrens: []
            },
            {
                id: 249,
                name: "Антибіотики при пневмонії",
                url: "https:\/\/www.apteka24.ua\/antibiotiki\/pri-pnevmonii\/",
                childrens: []
            },
            {
                id: 250,
                name: "Антибіотики при діареї",
                url: "https:\/\/www.apteka24.ua\/antibiotiki\/pri-diarei\/",
                childrens: []
            },
            {
                id: 251,
                name: "Антибіотики у стоматології",
                url: "https:\/\/www.apteka24.ua\/antibiotiki\/v-stomatologii\/",
                childrens: []
            },
            {
                id: 252,
                name: "Антибіотики у гінекології",
                url: "https:\/\/www.apteka24.ua\/antibiotiki\/v-ginekologii\/",
                childrens: []
            },
            {
                id: 253,
                name: "Антибіотики у дерматології",
                url: "https:\/\/www.apteka24.ua\/antibiotiki\/v-dermatologii\/",
                childrens: []
            },
            {
                id: 254,
                name: "Антибіотики для дітей",
                url: "https:\/\/www.apteka24.ua\/antibiotiki\/dlya-detey\/",
                childrens: []
            },
            {
                id: 255,
                name: "Антибіотики для очей",
                url: "https:\/\/www.apteka24.ua\/antibiotiki\/dlya-glaz\/",
                childrens: []
            }
        ]
    },
    {
        id: 197,
        name: "Товари для косметологів",
        url: "https:\/\/www.apteka24.ua\/kosmetologiya\/",
        icon: `${env.getMainImageRepository()}/icons/menu/CosmProd.svg`,
        promo: {
            url: "",
            preview: "@Image"
        },
        popular: {
            title: "Популярні категорії",
            items: []
        },
        subItems: [
            {
                id: 200,
                name: "Рукавички",
                url: "https:\/\/www.apteka24.ua\/kosmetologiya\/perchatki\/",
                childrens: []
            },
            {
                id: 201,
                name: "Шприци",
                url: "https:\/\/www.apteka24.ua\/kosmetologiya\/shpritsy\/",
                childrens: []
            },
            {
                id: 203,
                name: "Антисептики та дезінфікуючі",
                url: "https:\/\/www.apteka24.ua\/kosmetologiya\/antiseptiki-i-dezinfitsiruyushchie\/",
                childrens: []
            },
            {
                id: 202,
                name: "Анестетики",
                url: "https:\/\/www.apteka24.ua\/kosmetologiya\/anesteziya\/",
                childrens: []
            },
            {
                id: 232,
                name: "Ботулінотерапія",
                url: "https:\/\/www.apteka24.ua\/kosmetologiya\/botulinoterapiya\/",
                childrens: []
            },
            {
                id: 199,
                name: "Контурна пластика",
                url: "https:\/\/www.apteka24.ua\/kosmetologiya\/inektsii-krasoty\/",
                childrens: []
            }
        ]
    },
    {
        id: 14,
        name: "Товари для мам та дітей",
        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/",
        icon: `${env.getMainImageRepository()}/icons/menu/Child&MomsProd.svg`,
        promo: {
            url: "",
            preview: "@Image"
        },
        popular: {
            title: "Популярні категорії",
            items: []
        },
        subItems: [
            {
                id: 93,
                name: "Дитячі аксесуари",
                url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/detskie-aksessuary\/",
                childrens: [
                    {
                        id: 450,
                        name: "Пустушки",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/detskie-aksessuary\/pustyshki\/",
                        childrens: []
                    },
                    {
                        id: 452,
                        name: "Дитячі соски",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/detskie-aksessuary\/detskie-soski\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 1082,
                name: "Товари для мам",
                url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/tovary-dlya-mam\/",
                childrens: [
                    {
                        id: 1083,
                        name: "Молоковідсмоктувач",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/tovary-dlya-mam\/molokootsos\/",
                        childrens: []
                    },
                    {
                        id: 1086,
                        name: "Прокладки для грудей",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/tovary-dlya-mam\/prokladki-dlya-grudi\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 1080,
                name: "Аксесуари для годування",
                url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/aksessuary-dlya-kormleniya\/",
                childrens: [
                    {
                        id: 1081,
                        name: "Комплекти для годування",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/aksessuary-dlya-kormleniya\/komplekty-dlya-kormleniya\/",
                        childrens: []
                    },
                    {
                        id: 944,
                        name: "Соски для пляшечок",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/aksessuary-dlya-kormleniya\/soski-dlya-butylochek\/",
                        childrens: []
                    },
                    {
                        id: 453,
                        name: "Дитячі пляшечки та поїлки",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/aksessuary-dlya-kormleniya\/detskie-butylochki\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 139,
                name: "Дитяча косметика",
                url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/detskaya-kosmetika\/",
                childrens: [
                    {
                        id: 948,
                        name: "Дитяче рідке мило",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/detskaya-kosmetika\/detskoe-zhidkoe-mylo\/",
                        childrens: []
                    },
                    {
                        id: 444,
                        name: "Дитячий шампунь",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/detskaya-kosmetika\/detskiy-shampun\/",
                        childrens: []
                    },
                    {
                        id: 445,
                        name: "Дитяча олія",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/detskaya-kosmetika\/detskoe-maslo\/",
                        childrens: []
                    },
                    {
                        id: 446,
                        name: "Дитяча присипка",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/detskaya-kosmetika\/detskaya-prisypka\/",
                        childrens: []
                    },
                    {
                        id: 447,
                        name: "Дитяче мило",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/detskaya-kosmetika\/detskoe-mylo\/",
                        childrens: []
                    },
                    {
                        id: 448,
                        name: "Дитячий крем",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/detskaya-kosmetika\/detskiy-krem\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 1084,
                name: "Гігієна малюків",
                url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/gigiena-malyshey\/",
                childrens: [
                    {
                        id: 1085,
                        name: "Ватні палички",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/gigiena-malyshey\/vatnye-palochki\/",
                        childrens: []
                    },
                    {
                        id: 136,
                        name: "Підгузки",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/gigiena-malyshey\/detskie-podguzniki\/",
                        childrens: []
                    },
                    {
                        id: 137,
                        name: "Пелюшки",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/gigiena-malyshey\/detskie-pelenki\/",
                        childrens: []
                    },
                    {
                        id: 138,
                        name: "Вологі серветки",
                        url: "https:\/\/www.apteka24.ua\/mama-i-malysh\/gigiena-malyshey\/detskie-vlazhnye-salfetki\/",
                        childrens: []
                    }
                ]
            }
        ]
    },
    {
        id: 975,
        name: "Оптика",
        url: "https:\/\/www.apteka24.ua\/optika\/",
        icon: `${env.getMainImageRepository()}/icons/menu/Optics.svg`,
        promo: {
            url: "",
            preview: "@Image"
        },
        popular: {
            title: "Популярні категорії",
            items: []
        },
        subItems: [
            {
                id: 976,
                name: "Контактні лінзи",
                url: "https:\/\/www.apteka24.ua\/optika\/kontaktnye-linzy\/",
                childrens: [
                    {
                        id: 1098,
                        name: "Лінзи День-Ніч",
                        url: "https:\/\/www.apteka24.ua\/optika\/kontaktnye-linzy\/linzy-den-noch\/",
                        childrens: []
                    },
                    {
                        id: 1099,
                        name: "Лінзи на 1 місяць",
                        url: "https:\/\/www.apteka24.ua\/optika\/kontaktnye-linzy\/linzy-na-1-mesyats\/",
                        childrens: []
                    },
                    {
                        id: 1100,
                        name: "Одноденні лінзи",
                        url: "https:\/\/www.apteka24.ua\/optika\/kontaktnye-linzy\/odnodnevnye-linzy\/",
                        childrens: []
                    },
                    {
                        id: 1101,
                        name: "Квартальні лінзи",
                        url: "https:\/\/www.apteka24.ua\/optika\/kontaktnye-linzy\/kvartalnye-linzy\/",
                        childrens: []
                    },
                    {
                        id: 977,
                        name: "Мультифокальні лінзи",
                        url: "https:\/\/www.apteka24.ua\/optika\/kontaktnye-linzy\/multifokalnye-linzy\/",
                        childrens: []
                    },
                    {
                        id: 978,
                        name: "Лінзи для корекції далекозоркості",
                        url: "https:\/\/www.apteka24.ua\/optika\/kontaktnye-linzy\/linzy-dlya-dali\/",
                        childrens: []
                    },
                    {
                        id: 979,
                        name: "Кольорові лінзи",
                        url: "https:\/\/www.apteka24.ua\/optika\/kontaktnye-linzy\/tsvetnye-linzy\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 587,
                name: "Розчин для лінз",
                url: "https:\/\/www.apteka24.ua\/optika\/rastvor-dlya-linz-\/",
                childrens: []
            }
        ]
    },
    {
        id: 48,
        name: "Косметика та гігієна",
        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/",
        icon: `${env.getMainImageRepository()}/icons/menu/Cosmetics&Gigien.svg`,
        promo: {
            url: "",
            preview: "@Image"
        },
        popular: {
            title: "Популярні категорії",
            items: []
        },
        subItems: [
            {
                id: 178,
                name: "Засоби для обличчя",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-litsa\/",
                childrens: [
                    {
                        id: 475,
                        name: "Гігієнічні помади",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-litsa\/gigienicheskie-pomady\/",
                        childrens: []
                    },
                    {
                        id: 497,
                        name: "Засоби від куперозу та демодекозу.",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-litsa\/sredstva-ot-kuperoza-i-demodekoza\/",
                        childrens: []
                    },
                    {
                        id: 901,
                        name: "Лосьйон для обличчя",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-litsa\/loson-dlya-litsa\/",
                        childrens: []
                    },
                    {
                        id: 900,
                        name: "Сироватки та еліксири",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-litsa\/emulsiya-dlya-litsa\/",
                        childrens: []
                    },
                    {
                        id: 855,
                        name: "Очищувальний засіб для обличчя",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-litsa\/ochishchayushchee-sredstvo-dlya-litsa\/",
                        childrens: []
                    },
                    {
                        id: 500,
                        name: "Маски для обличчя",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-litsa\/maski-dlya-litsa\/",
                        childrens: []
                    },
                    {
                        id: 499,
                        name: "Засоби для зняття макіяжу",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-litsa\/sredstva-dlya-snyatiya-makiyazha\/",
                        childrens: []
                    },
                    {
                        id: 472,
                        name: "Креми для обличчя",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-litsa\/kremy-dlya-litsa\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 176,
                name: "Догляд за волоссям",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/ukhod-za-volosami\/",
                childrens: [
                    {
                        id: 1069,
                        name: "Олії для волосся",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/ukhod-za-volosami\/masla-dlya-volos\/",
                        childrens: []
                    },
                    {
                        id: 889,
                        name: "Кондиціонер",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/ukhod-za-volosami\/konditsionery-dlya-volos\/",
                        childrens: []
                    },
                    {
                        id: 503,
                        name: "Бальзами та маски для волосся",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/ukhod-za-volosami\/balzamy-i-maski-dlya-volos\/",
                        childrens: []
                    },
                    {
                        id: 483,
                        name: "Засоби від випадіння волосся",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/ukhod-za-volosami\/sredstva-protiv-vypadeniya-volos\/",
                        childrens: []
                    },
                    {
                        id: 349,
                        name: "Шампуні",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/ukhod-za-volosami\/shampuni-dlya-volos\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 181,
                name: "Засоби для тіла",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-tela\/",
                childrens: [
                    {
                        id: 893,
                        name: "Лосьйон для тіла",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-tela\/loson-dlya-tela\/",
                        childrens: []
                    },
                    {
                        id: 473,
                        name: "Крем для тіла",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-tela\/kremy-dlya-tela\/",
                        childrens: []
                    },
                    {
                        id: 1070,
                        name: "Антиперспіранти",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-tela\/antiperspiranty\/",
                        childrens: []
                    },
                    {
                        id: 501,
                        name: "Засоби для засмаги",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-tela\/kremy-dlya-zagara\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 175,
                name: "Засоби для душу та ванни",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-dusha-i-vanny\/",
                childrens: [
                    {
                        id: 803,
                        name: "Spa набори",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-dusha-i-vanny\/spa-nabory\/",
                        childrens: []
                    },
                    {
                        id: 801,
                        name: "Скраб",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-dusha-i-vanny\/skrab-dlya-litsa-i-tela\/",
                        childrens: []
                    },
                    {
                        id: 938,
                        name: "Засоби для інтимної гігієни",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-dusha-i-vanny\/sredstva-dlya-intimnoy-gigieny\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 180,
                name: "Догляд за руками та нігтями",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/ukhod-za-rukami-i-nogtyami\/",
                childrens: [
                    {
                        id: 854,
                        name: "Крем для рук",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/ukhod-za-rukami-i-nogtyami\/krem-dlya-ruk\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 179,
                name: "Засоби для ніг",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-nog\/",
                childrens: [
                    {
                        id: 912,
                        name: "Дезодорант для ніг",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-nog\/dezodorant-dlya-nog\/",
                        childrens: []
                    },
                    {
                        id: 474,
                        name: "Креми для ніг",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-nog\/kremy-dlya-nog\/",
                        childrens: []
                    },
                    {
                        id: 504,
                        name: "Засоби для загоєння тріщин на п'ятах",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-dlya-nog\/sredstva-dlya-zazhivleniya-treshchin-na-pyatkakh\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 857,
                name: "Декоративна косметика",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/dekorativnaya-kosmetika\/",
                childrens: [
                    {
                        id: 496,
                        name: "Тональні та матуючі засоби",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/dekorativnaya-kosmetika\/tonalnye-i-matiruyushchie-sredstva\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 171,
                name: "Засоби жіночої гігієни",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-zhenskoy-gigieny\/",
                childrens: [
                    {
                        id: 454,
                        name: "Тампони",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-zhenskoy-gigieny\/tampony\/",
                        childrens: []
                    },
                    {
                        id: 487,
                        name: "Прокладки щоденні",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-zhenskoy-gigieny\/prokladki-ezhednevnye\/",
                        childrens: []
                    },
                    {
                        id: 488,
                        name: "Прокладки для критичних днів",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/sredstva-zhenskoy-gigieny\/prokladki-dlya-kriticheskikh-dney\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 87,
                name: "Лікувальна косметика",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/lechebnaja-kosmetika\/",
                childrens: []
            },
            {
                id: 85,
                name: "Гігієна порожнини рота",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/gigiena-polosti-rta\/",
                childrens: [
                    {
                        id: 1074,
                        name: "Освіжувач для ротової порожнини",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/gigiena-polosti-rta\/osvezhitel-dlya-polosti-rta\/",
                        childrens: []
                    },
                    {
                        id: 348,
                        name: "Зубні щітки",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/gigiena-polosti-rta\/zubnye-shchetki\/",
                        childrens: []
                    },
                    {
                        id: 468,
                        name: "Засоби для зубних протезів",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/gigiena-polosti-rta\/sredstva-dlya-zubnykh-protezov\/",
                        childrens: []
                    },
                    {
                        id: 469,
                        name: "Зубні нитки стрічки",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/gigiena-polosti-rta\/zubnye-niti-lenty\/",
                        childrens: []
                    },
                    {
                        id: 470,
                        name: "Зубні пасти",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/gigiena-polosti-rta\/zubnye-pasty\/",
                        childrens: []
                    },
                    {
                        id: 484,
                        name: "Зубні щітки для дітей",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/gigiena-polosti-rta\/zubnye-shchetki-dlya-detey\/",
                        childrens: []
                    },
                    {
                        id: 485,
                        name: "Ополіскувачі для рота",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/gigiena-polosti-rta\/opolaskivateli-dlya-rta\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 90,
                name: "Лікувальні олії",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/lechebnye-masla\/",
                childrens: [
                    {
                        id: 598,
                        name: "Масажна олія",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/lechebnye-masla\/massazhnoe-maslo\/",
                        childrens: []
                    },
                    {
                        id: 599,
                        name: "Ефірні масла",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/lechebnye-masla\/efirnye-masla-\/",
                        childrens: []
                    },
                    {
                        id: 600,
                        name: "Ефірні олії для волосся",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/lechebnye-masla\/efirnye-masla-dlya-volos-\/",
                        childrens: []
                    },
                    {
                        id: 601,
                        name: "Ефірні олії для обличчя",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/lechebnye-masla\/efirnye-masla-dlya-litsa-\/",
                        childrens: []
                    },
                    {
                        id: 602,
                        name: "Ефірні олії для шкіри",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/lechebnye-masla\/efirnye-masla-dlya-kozhi-\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 142,
                name: "Диспорт",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/disport\/",
                childrens: []
            },
            {
                id: 125,
                name: "Косметика Реванесс - Revanesse",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/revanes\/",
                childrens: []
            },
            {
                id: 1071,
                name: "Для чоловіків",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/dlya-muzhchin\/",
                childrens: [
                    {
                        id: 802,
                        name: "Засоби для бороди",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/dlya-muzhchin\/sredstva-dlya-borody\/",
                        childrens: []
                    },
                    {
                        id: 922,
                        name: "Шампунь для чоловіків",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/dlya-muzhchin\/shampun-dlya-muzhchin\/",
                        childrens: []
                    }
                ]
            },
            {
                id: 495,
                name: "Догляд за шкірою навколо очей",
                url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/kremy-dlya-kozhi-vokrug-glaz\/",
                childrens: [
                    {
                        id: 1061,
                        name: "Креми для шкіри навколо очей",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/kremy-dlya-kozhi-vokrug-glaz\/kremy\/",
                        childrens: []
                    },
                    {
                        id: 1062,
                        name: "Сироватки для повік",
                        url: "https:\/\/www.apteka24.ua\/kosmetika-i-gigiena\/kremy-dlya-kozhi-vokrug-glaz\/syvorotki\/",
                        childrens: []
                    }
                ]
            }
        ]
    },
    {
        id: 1200,
        name: "Сексуальне здоров'я",
        url: "https://www.apteka24.ua/seksualnoe-zdorove/",
        icon: `${env.getMainImageRepository()}/icons/menu/Sexual.svg`,
        promo: {
            url: "",
            preview: "@Image"
        },
        popular: {
            title: "Популярні категорії",
            items: []
        },
        subItems: [
            {
                id: 1201,
                name: "Контрацепція",
                url: "https://www.apteka24.ua/seksualnoe-zdorove/kontratseptsia/",
                childrens: [
                    {
                        id: 1207,
                        name: "Презервативи",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/kontratseptsia/prezervativu/",
                        childrens: []
                    },
                    {
                        id: 1208,
                        name: "Гормональні контрацептиви",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/kontratseptsia/gormonalnye-kontratseptivu/",
                        childrens: []
                    },
                    {
                        id: 1209,
                        name: "Спіралі",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/kontratseptsia/spirali/",
                        childrens: []
                    },
                    {
                        id: 1210,
                        name: "Сперміциди",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/kontratseptsia/spermitsidy/",
                        childrens: []
                    },
                    {
                        id: 1211,
                        name: "Екстрена контрацепція",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/kontratseptsia/ekstrennaya-kontratseptsiya/",
                        childrens: []
                    }
                ]
            },
            {
                id: 1202,
                name: "Змащення та зволожуючі гелі",
                url: "https://www.apteka24.ua/seksualnoe-zdorove/smazki-i-uvlazhnyayushchie-geli/",
                childrens: [
                    {
                        id: 1212,
                        name: "Змащення на водній основі",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/smazki-i-uvlazhnyayushchie-geli/smazki-na-vodnoy-osnove/",
                        childrens: []
                    },
                    {
                        id: 1213,
                        name: "Змащення, що підсилюють відчуття",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/smazki-i-uvlazhnyayushchie-geli/smazki-usilivayushchie-oshchushcheniya/",
                        childrens: []
                    },
                    {
                        id: 1214,
                        name: "Змащення на силіконовій основі",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/smazki-i-uvlazhnyayushchie-geli/smazki-na-silikonovoy-osnove/",
                        childrens: []
                    },
                    {
                        id: 1215,
                        name: "Натуральні гель-мастила",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/smazki-i-uvlazhnyayushchie-geli/naturalnye-gel-smazki/",
                        childrens: []
                    }
                ]
            },
            {
                id: 1203,
                name: "Інтимний догляд",
                url: "https://www.apteka24.ua/seksualnoe-zdorove/intimnyy-ukhod/",
                childrens: [
                    {
                        id: 1216,
                        name: "Жіночі засоби для інтимної гігієни",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/intimnyy-ukhod/zhenskie-sredstva-dlya-intimnoy-gigieny/",
                        childrens: []
                    },
                    {
                        id: 1217,
                        name: "Серветки для інтимної гігієни",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/intimnyy-ukhod/salfetki-dlya-intimnoy-gigieny/",
                        childrens: []
                    },
                    {
                        id: 1218,
                        name: "Антисептики для інтимної гігієни",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/intimnyy-ukhod/antiseptiki-dlya-intimnoy-gigieny/",
                        childrens: []
                    },
                    {
                        id: 1219,
                        name: "Засоби відновлення жіночої мікрофлори",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/intimnyy-ukhod/sredstva-dlya-vosstanovleniya-zhenskoy-mikroflory/",
                        childrens: []
                    }
                ]
            },
            {
                id: 1204,
                name: "Препарати для ерекції",
                url: "https://www.apteka24.ua/seksualnoe-zdorove/preparaty-dlya-erektsii/",
                childrens: []
            },
            {
                id: 1205,
                name: "Добавки харчові для сексуального здоров'я",
                url: "https://www.apteka24.ua/seksualnoe-zdorove/pishchevye-dobavki-dlya-seksualnogo-zdorovya/",
                childrens: [
                    {
                        id: 1220,
                        name: "Для нього",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/pishchevye-dobavki-dlya-seksualnogo-zdorovya/dlya-nego/",
                        childrens: []
                    },
                    {
                        id: 1221,
                        name: "Для неї",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/pishchevye-dobavki-dlya-seksualnogo-zdorovya/dlya-neye/",
                        childrens: []
                    }
                ]
            },
            {
                id: 1206,
                name: "Зачаття та вагітність",
                url: "https://www.apteka24.ua/seksualnoe-zdorove/zachatie-i-beremennost/",
                childrens: [
                    {
                        id: 1222,
                        name: "Тести на овуляцію",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/zachatie-i-beremennost/testy-na-ovulyatsiyu/",
                        childrens: []
                    },
                    {
                        id: 1223,
                        name: "Тести на вагітність",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/zachatie-i-beremennost/testy-na-beremennost/",
                        childrens: []
                    },
                    {
                        id: 1224,
                        name: "Добавки харчові до зачаття",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/zachatie-i-beremennost/pishchevye-dobavki-do-zachatiya/",
                        childrens: []
                    },
                    {
                        id: 1225,
                        name: "Харчові добавки та вітаміни для вагітних",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/zachatie-i-beremennost/pishchevye-dobavki-i-vitaminy-dlya-beremennykh/",
                        childrens: []
                    },
                    {
                        id: 1226,
                        name: "Догляд за шкірою від розтяжок",
                        url: "https://www.apteka24.ua/seksualnoe-zdorove/zachatie-i-beremennost/ukhod-za-kozhey-ot-rastyazhek/",
                        childrens: []
                    }
                ]
            }
        ]
    }
];
