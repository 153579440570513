class Transformer {
    constructor(props) {
        this._Env = props.dependencies.Env;
    }

    /**
     * @public
     * @method transform
     * @param data {Object}
     * @return {Object}
     */
    transform(data) {
        return {
            id: data.id,
            callback: data.callback,
            createdAt: data.createdAt,
            status: data.status,
            history: data.history || [],
            customer: data.client,
            recipient: data.recipient,
            delivery: {
                title: data.delivery.title || "",
                address: data.delivery.address || "",
                ttn: data.delivery.ttn,
                price: data.delivery.price
            },
            payment: data.payment,
            basket: {
                items: data.items
            },
            price: {
                current: data.price?.productsTotalPrice,
                total: data.price?.totalPrice
            }
        };
    }

    /**
     * @method transformItems
     * @param items {Array}
     * @return {Array}
     */
    transformItems(items) {
        return (items || []).map((item) => this.transform(item));
    }
}

export default Transformer;
