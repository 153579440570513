class MarketingActivity {
    constructor() {
        /**
         * @private
         * @property _factorForDoubleBonus
         * @type {number}
         */
        this._factorForDoubleBonus = 2;

        /**
         * @property _maxOrderPriceForGovernmentalSupport
         * @type {number}
         * @private
         */
        this._maxOrderPriceForGovernmentalSupport = 1000;
    }

    /**
     * @public
     * @method getDoubleBonusFactor
     * @returns {number}
     */
    getDoubleBonusFactor() {
        return this._factorForDoubleBonus;
    }

    /**
     * @method getMaxOrderPriceForGovernmentalSupport
     * @returns {number}
     */
    getMaxOrderPriceForGovernmentalSupport() {
        return this._maxOrderPriceForGovernmentalSupport;
    }
}

export default MarketingActivity;
