import _ from "lodash";

import Entity from "app/core/entities/Entity";

class Alert extends Entity {
    /**
     * @method getTitle
     * @returns {string}
     */
    getTitle() {
        return this.entity.title || "";
    }

    /**
     * @method setTitle
     * @param title {string}
     * @returns {Alert}
     */
    setTitle(title) {
        if (_.isString(title)) {
            this.entity.title = title;
        }

        return this;
    }

    /**
     * @method getDescription
     * @returns {string}
     */
    getDescription() {
        return this.entity.description || "";
    }

    /**
     * @method setDescription
     * @param description {string}
     * @returns {Alert}
     */
    setDescription(description) {
        if (_.isString(description)) {
            this.entity.description = description;
        }

        return this;
    }
}

export default Alert;
